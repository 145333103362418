<section class="primary-info b-gradiente">
  <div class="container container-xl pb-100">
    <div class="row w-text">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 banner">
        <h1 style="padding-top: 45px" class="titulo">
          Painel Saúde Fiocruz <strong>Atenção Primária</strong>
        </h1>

        <p class="m-t-50">
          O Painel Saúde Fiocruz é um software criado para auxiliar gestores e
          profissionais de saúde na gestão da clínica e do cuidado.
        </p>
        <p>
          Imagine se fosse possível consultar painéis feitos sob medida para os
          profissionais da atenção primária utilizando dados locais do
          município ou da própria unidade básica de saúde. Não seria necessário
          esperar o upload para o Ministério da Saúde e a liberação de painéis
          no e-Gestor para acompanhar a situação da sua equipe e dos cuidados
          pendentes para os cidadãos da sua área de abrangência.
        </p>
        <p>
          É isso que o Painel Saúde Fiocruz faz: dá autonomia aos municípios e
          profissionais de saúde para usar os próprios dados para apoiar a
          qualificação da APS. Somos finalistas no prêmio do MIT para melhores
          softwares para atenção primária no mundo:
          <a
            href="https://solve.mit.edu/challenges/performance-improvement/solutions/66660"
            target="_blank"
            >MIT Solve PHC Performance</a
          >
        </p>
        <div class="col-lg-12 m-t-50">
          <button
            class="btn btn-clean-purple"
            (click)="
              downloadApp(
                dowloadAppModal,
                dowloadConcluido,
                termsOfUseModal,
                verifyAccount
              )
            "
          >
            FAZER DOWNLOAD
          </button>
        </div>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <img
          class="computador-banner"
          src="assets/imgs/NotebookHome.png"
          alt="Imagem de computador com um circuito no fundo."
        />
      </div>
    </div>
  </div>

  <div class="white-triangule-right"></div>
</section>
<section class="pq-usar">
  <div class="container container-xl">
    <h2 class="titulo m-b-30">POR QUE USAR</h2>

    <p class="box-text text-grey">
      O Painel Saúde Fiocruz é uma ferramenta de apoio à vigilância e
      organização da atenção à saúde.
    </p>
    <p class="box-text">
      Estão implantados relatórios para entender o perfil da população
      cadastrada, para acompanhamento das gestantes e pessoas com hipertensão e
      diabetes.
    </p>
  </div>
</section>
<section class="como-usar primary-info b-gradiente-new">
  <div class="container container-xl">
    <h2 class="titulo m-b-30">COMO USAR</h2>
    <p class="box-text">
      Para utilizar o Painel, é necessário ser um profissional de saúde
      autorizado a acessar dados pessoais da atenção primária.
    </p>

    <p class="box-text">
      O software é instalado em um computador com Windows e deve ter acesso a um
      banco de dados do e-SUS APS, seja na própria UBS ou no centralizador
      municipal.
    </p>

    <p class="box-text">
      O tutorial com o passo a passo da instalação se encontra no Manual de
      instalação.
    </p>
    <div class="row">
      <div class="col-lg-12">
        <button class="btn btn-clean">FAZER DOWNLOAD</button>
      </div>
    </div>
  </div>
  <div class="white-triangule-left grey"></div>
</section>
<section class="manuais-grey" style="padding: 10px">
  <div class="container container-xl">
    <h2 class="titulo">MANUAIS</h2>
    <div class="row m-t-50">
      <div class="col-md-6 m-b-50">
        <div class="pull-right btn-download-box">
          <img
            src="assets/imgs/PessoasHome.png"
            class="img-btn-download"
            alt="Um casal olhando para algo não específico"
            style="width: 100px"
          />
          <button class="btn btn-manual">MANUAL DO USUÁRIO</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="pull-left btn-download-box">
          <img
            src="assets/imgs/EngrenagensHome.png"
            class="img-btn-download"
            alt="Engrenagens de sistemas"
            style="width: 83px"
          />
          <button class="btn btn-manual">MANUAL DE INSTALAÇÃO</button>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #dowloadAppModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Autenticação necessária</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#login"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Entrar
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#register"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Registrar
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane active" id="login" role="tabpanel">
        <p></p>
        <p></p>
        <form
          class="container"
          [formGroup]="loginForm"
          (submit)="
            logar(
              $event,
              dowloadAppModal,
              dowloadConcluido,
              termsOfUseModal,
              verifyAccount
            )
          "
        >
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control {{
                isValidInput(loginForm, 'username') ? 'is-invalid' : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com o email"
              formControlName="username"
              name="email"
              required
            />
            <div
              *ngIf="isValidInput(loginForm, 'username')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="this.loginForm.controls['username'].errors.required">
                O campo email é obrigatório.
              </p>

              <p *ngIf="this.loginForm.controls['username'].errors.email">
                O email não é válido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Senha</label>
            <input
              type="password"
              class="form-control {{
                isValidInput(loginForm, 'password') ? 'is-invalid' : 'is-valid'
              }} "
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com uma senha segura"
              name="password"
              formControlName="password"
              required
            />

            <div
              *ngIf="isValidInput(loginForm, 'password')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="this.loginForm.controls['password'].errors.required">
                A senha é obrigatória.
              </p>

              <p *ngIf="this.loginForm.controls['password'].errors.minlength">
                A senha deve ter no mínimo 4 caracteres.
              </p>
            </div>
          </div>

          <div style="margin-top: 10px" class="">
            <a
              href="#"
              (click)="forgotPassword(dowloadAppModal, forgotPasswordModal)"
              id="forgot_pswd"
              >Esqueceu a senha?</a
            >
          </div>
          <p></p>
          <button
            type="submit"
            class="btn btn-outline-dark"
            [disabled]="loginForm.invalid"
            (click)="modal.close('Entrar')"
          >
            Entrar
          </button>
        </form>
      </div>
      <div class="tab-pane" id="register" role="tabpanel">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <form
          class="container"
          [formGroup]="registerForm"
          (submit)="
            register(
              $event,
              dowloadAppModal,
              dowloadConcluido,
              termsOfUseModal,
              verifyAccount
            )
          "
        >
          <div class="form-group">
            <label for="name">Nome</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'name') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Nome"
              name="newUserName"
              formControlName="name"
            />

            <div
              *ngIf="isValidInput(registerForm, 'name')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['name'].errors.required">
                O campo nome é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">CPF</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'cpf') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="CPF"
              name="newUserCpf"
              mask="000.000.000-00"
              formControlName="cpf"
            />
            <div
              *ngIf="isValidInput(registerForm, 'cpf')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['cpf'].errors.required">
                O campo cpf é obrigatório.
              </p>
              <p *ngIf="registerForm.controls['cpf'].errors.minlength">
                O campo cpf está inválido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Telefone</label>
            <input
              type="tel"
              class="form-control {{
                isValidInput(registerForm, 'phone') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Telefone"
              formControlName="phone"
              mask="(00) 00000-0000"
            />
            <div
              *ngIf="isValidInput(registerForm, 'phone')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['phone'].errors.required">
                O campo phone é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Município</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'city') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Digite o nome do município"
              name="newUserMunicipio"
              formControlName="city"
            />
            <div
              *ngIf="isValidInput(registerForm, 'city')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['city'].errors.required">
                O campo município é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control {{
                isValidInput(registerForm, 'email') ? 'is-invalid' : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com o email"
              name="newUserEmail"
              formControlName="email"
            />
            <small id="emailHelp" class="form-text text-muted"
              >Entre com um email válido.</small
            >
            <div
              *ngIf="isValidInput(registerForm, 'email')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['email'].errors.required">
                O campo email é obrigatório.
              </p>
              <p *ngIf="registerForm.controls['email'].errors.email">
                O campo email não é válido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Senha</label>
            <input
              type="password"
              class="form-control {{
                isValidInput(registerForm, 'password')
                  ? 'is-invalid'
                  : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com uma senha seguira"
              name="newUserPassword"
              formControlName="password"
            />
            <small id="emailHelp" class="form-text text-muted"
              >Procure misturar letras e números para uma senha segura.</small
            >
            <div
              *ngIf="isValidInput(registerForm, 'password')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['password'].errors.required">
                O campo senha é obrigatório.
              </p>
            </div>
          </div>
          <button
            style="margin-top: 20px"
            type="submit"
            class="btn btn-outline-dark"
            (click)="modal.close('Registrar')"
          >
            Registrar
          </button>
        </form>
      </div>
    </div>

    <script>
      $(function () {
        $("#myTab a:last").tab("show");
      });
    </script>
  </div>
</ng-template>

<ng-template #forgotPasswordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Esqeuceu a senha?</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-md-offset-4">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">
                <h3><i class="fa fa-lock fa-4x"></i></h3>
                <h2 class="text-center">Recuperação de senha</h2>
                <p>Entre com seu email para a recuperação de senha.</p>
                <div class="panel-body">
                  <form
                    id="register-form"
                    role="form"
                    autocomplete="off"
                    class="form"
                    method="post"
                    [formGroup]="forgotPasswordForm"
                    (submit)="submitForgotPassword($event, forgotPasswordModal)"
                  >
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        class="form-control {{
                          isValidInput(forgotPasswordForm, 'email')
                            ? 'is-invalid'
                            : 'is-valid'
                        }}"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Entre com o email"
                        formControlName="email"
                        name="email"
                        required
                      />
                      <div
                        *ngIf="isValidInput(forgotPasswordForm, 'email')"
                        class="alert alert-danger"
                        role="alert"
                      >
                        <p
                          *ngIf="
                            this.forgotPasswordForm.controls['email'].errors
                              .required
                          "
                        >
                          O campo email é obrigatório.
                        </p>

                        <p
                          *ngIf="
                            this.forgotPasswordForm.controls['email'].errors
                              .email
                          "
                        >
                          O email não é válido.
                        </p>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                  <input id="email" name="email" placeholder="email" class="form-control"  type="email">
                                </div>
                              </div> -->
                    <div class="form-group" style="margin-top: 30px">
                      <!-- <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Enviar email" type="submit"> -->
                      <button
                        name="recover-submit"
                        class="btn btn-clean-purple"
                        value="Trocar senha2"
                        type="submit"
                      >
                        Enviar email
                      </button>
                    </div>

                    <input
                      type="hidden"
                      class="hide"
                      name="token"
                      id="token"
                      value=""
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dowloadConcluido let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Download Iniciado com sucesso</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" style="text-align: justify">
    <div class="row">
      <div class="col-md-4 ms-auto"></div>
      <div class="col-md-4 ms-auto">
        <img
          src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
          width="125"
          height="120"
          style="display: block; border: 0px"
        />
      </div>
      <div class="col-md-4 ms-auto"></div>
    </div>
    Você acaba de fazer o download do software
    <strong>PAINÉIS e-SUS APS</strong>. Abaixo paresentamos o seu número serial
    para instalação do o sofware. Uma cópia do número serial também foi enviado
    ao seu email. Esse número de serial é único e não deve ser compartilhado.
    <p></p>
    Caso precise gerar outro número de serial, clique no menu
    <strong>"Serial"</strong>
    apresentado no menu superior, e digite o seu endereço de email. Em alguns
    minutos o número de serial será enviado para o e-mail informado.

    <p></p>
    <p></p>
    <div class="card text-dark bg-light mb-3">
      <div class="card-body">
        {{ serialNumber }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #termsOfUseModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">
      TERMOS DE USO E POLÍTICA DE PRIVACIDADE
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" style="text-align: justify; margin-bottom: 30px">
    <p>
      Este é um Contrato firmado entre a Fundação Oswaldo Cruz (ENDEREÇO),
      doravante denominada <strong>LICENCIANTE</strong> e a
      <strong>Secretaria Municipal de Saúde (ENDEREÇO)</strong>, doravante
      denominada <strong>LICENCIADA</strong>.
    </p>
    <p>
      Este
      <strong>TERMOS DE USO E POLÍTICA DE PRIVACIDADE (“TERMO”)</strong> rege as
      condições de uso do <strong>Painéis e-SUS APS (“SOFTWARE”)</strong>, e é
      um termo de aceitação obrigatória para o uso do <strong>SOFTWARE</strong>.
      O uso do <strong>Painéis e-SUS APS</strong> significa que
      <strong>LICENCIADA</strong>, o <strong>USUÁRIO</strong> e
      <strong>Administrador do Sistema</strong> leram e aceitaram todos os
      termos e condições presentes neste <strong>TERMO</strong> e concordam em
      cumpri-los. Sem a aceitação dos termos aqui apresentados não temos a
      autorização para coletar e processar os dados que precisamos para que o
      software funcionar.
    </p>
    <p>
      Ao utilizar o Painel e-SUS APS você se torna um USUÁRIO, e isso implica a
      aceitação plena e sem reserva de todos os itens do presente TERMO. Para
      aceitar o termo, ao final, clique em “Declaro que li e concordo com os
      termos e condições apresentados acima”. Caso não concorde com estes
      termos, clique em “Voltar” e não utilize o SOFTWARE.
    </p>
    <p>
      Este TERMO tem como principal objetivo, esclarecer os direitos e
      obrigações entre a instituição de saúde que o utilizará, LICENCIADA, o
      fornecedor do software, LICENCIANTE, e os USUÁRIOS, pessoas físicas que o
      utilizarão exercendo papéis aqui definidos. Esclarece também pontos
      importantes sobre a responsabilidade do servidor público e do profissional
      de saúde em relação a privacidade do cidadão.
    </p>
    <p>
      A LICENCIANTE é também controladora e operadora de dados pessoais
      coletados conforme definido neste termo.
    </p>
    <p>
      A LICENCIADA é a quem cabe fazer cumprir o presente Termo, e que atua como
      controladora de dados pessoais dos servidores, profissionais e cidadãos de
      seu município, ae qual poderá disponibilizar o Painéis e-SUS APS para os
      USUÁRIOS e Administrador do Sistema.
    </p>

    <p>
      O Administrador do Sistema, que exerce a função de operador de dados
      pessoais para os fins deste Termo, o qual deverá ser um funcionário da
      LICENCIADA.
    </p>
    <p>
      O USUÁRIO é o servidor público ou profissional de Saúde sob
      responsabilidade da LICENCIADA, que opera dados pessoais sem consentimento
      do titular dos mesmos (cidadão) para assistência à sua saúde, nos termos
      da legislação vigente e quem utilizará o Painéis e-SUS APS.
    </p>

    <h2>Painel e-SUS APS</h2>

    <p>
      O Painel e-SUS APS é um software público e gratuito, portanto não é
      permitido qualquer tipo de cobrança pela aquisição ou pelo seu uso. Seu
      desenvolvimento, fornecimento e suporte é feito pela Fundação Oswaldo Cruz
      (Fiocruz), em cooperação com a Secretaria de Atenção Primária à Saúde do
      Ministério da Saúde, por meio do Portal e-SUS Atenção Primária do
      Ministério da Saúde - https://aps.saude.gov.br/ape/esus - e do sítio
      Painel Saúde Fiocruz APS - http://painelsaude.fiocruz.br/aps .
    </p>

    <p>
      O Painel e-SUS APS auxiliará os gestores e equipes de atenção primária a:
    </p>

    <ul>
      <li>
        Identificar territórios de Unidades Básicas de Saúde com risco local de
        surto de Covid-19
      </li>
      <li>
        Monitorar o isolamento domiciliar das famílias com casos suspeitos
      </li>
      <li>
        Acompanhar casos leves de Covid-19 e identificar precocemente risco de
        evolução para formas moderada ou grave
      </li>
    </ul>

    <h2>Acesso ao Painel e-SUS APS pelo administrador do sistema</h2>

    <p>
      Um USUÁRIO deve ser designado como ADMINISTRADOR DO SISTEMA Painel e-SUS
      APS, tipicamente da coordenação Tecnologia da Informação ou estrutura
      equivalente. O ADMINISTRADOR DO SISTEMA responderá como Operador de dados
      pessoais, nos termos da Lei Geral de Proteção de Dados Pessoais (LGPD) -
      Lei nº 13.709, de 14 de Agosto de 2018.
    </p>

    <p>
      O acesso ao Sistema Painel e-SUS APS pelo ADMINISTRADOR DO SISTEMA é
      autorizado no processo de instalação, quando devem ser informados seu
      Nome, CPF, um endereço de correio eletrônico (e-mail) válido e pelo aceite
      deste TERMO DE USO.
    </p>

    <p>
      Softwares, especialmente desenvolvidos em caráter emergencial, são
      sujeitos a falhas. Compete a licenciada manter cópias de segurança
      atualizadas do banco de dados do Sistema e-SUS APS. Não nos
      responsabilizamos em caso de perda de informação se o acesso ao banco de
      dados for realizado utilizando uma senha de leitura e escrita, como por
      exemplo a senha padrão de administrador geral da base de dados do Sistema
      e-SUS APS.
    </p>
    <p>
      O computador onde o software é instalado deve ser protegido com senha
      pessoal identificando seu usuário, preferencialmente pela sua matrícula de
      servidor municipal e/ou CPF.
    </p>

    <h2>Proteção e tratamento dos dados pessoais dos cidadãos</h2>

    <p>
      O Painel e-SUS APS foi desenvolvido em conformidade com a Guia de Boas
      Práticas para Implementação na Administração Pública Federal da Lei Geral
      de Proteção de Dados (Guia de Boas Práticas LGPD), disponível em:
      https://www.gov.br/governodigital/pt-br/governanca-de-dados/guia-lgpd.pdf
    </p>

    <p>
      Para o seu funcionamento, o software realiza tratamento de dados pessoais
      sensíveis para a tutela da saúde do seu titular. Para sua correta
      utilização pelo Município em conformidade com a LGPD, a SMS deve garantir
      que:
    </p>

    <p>O USUÁRIO deve atender a, no mínimo, uma destas condições:</p>

    <ol>
      <li>
        Seja profissional de saúde, obrigado a sigilo profissional por lei
        regulamentadora de exercício profissional, como médicos, enfermeiros,
        etc..
      </li>
      <li>
        Seja servidor ou funcionário público municipal, obrigado a sigilo
        funcional.
      </li>
    </ol>

    <p>
      O USUÁRIO poderá gerar as visualizações e relatórios recomendados. Esses
      relatórios poderão conter dados pessoais. Cabe ao USUÁRIO a
      responsabilidade e guarda desses relatórios.
    </p>

    <h2>Suporte Online</h2>

    <p>
      A LICENCIANTE mantém um serviço online de suporte aos Painel e-SUS APS.
      Esse serviço é fornecido mediante cooperação técnica com o Ministério da
      Saúde, pelo formulário disponível em: https://painelsaude.fiocruz.br. O
      Suporte Online pode ser interrompido a qualquer momento.
    </p>

    <p>O serviço de suporte online possibilita:</p>
    <ol>
      <li>Login dos usuários para acesso aos relatórios</li>
      <li>Alteração da senha padrão para uma senha pessoal</li>
      <li>
        Recuperação de senha por meio de envio de link ao e-mail cadastrado
      </li>
      <li>
        Coleta de dados de configuração da instalação do local do Painel e-SUS
        APS
      </li>
      <li>Coleta de dados de telemetria e log do sistema</li>
      <li>
        Atendimento a chamados de suporte dos usuários, mediante preenchimento
        de formulário
      </li>
    </ol>

    <p>
      Para oferecer suporte ao software, a licenciante coleta regularmente, via
      Suporte Online, as seguintes informações relativas a máquina na qual o
      Painel e-SUS APS está rodando:
    </p>

    <ol>
      <li>
        Identificação do Administrador do Sistema: Nome completo, CPF, e-mail.
      </li>
      <li>
        Informações de configuração da máquina, incluindo: sistema operacional,
        memória, espaço disponível em disco, endereço IP, configuração de rede,
        entre outras.
      </li>
      <li>
        Registro de atividade do Painel e-SUS APS (arquivo de log de dados).
      </li>
    </ol>

    <h2>Proteção e tratamento dos dados pessoais dos usuários</h2>

    <p>
      O Painel e-SUS APS realiza tratamento dos seguintes dados dos USUÁRIOS:
    </p>

    <ol>
      <li>Nome completo</li>
      <li>E-mail</li>
      <li>CPF</li>
      <li>CNS</li>
      <li>Inscrição em conselho profissional</li>
      <li>Vínculo com estabelecimentos de saúde e equipes</li>
      <li>
        Log de dados, incluindo data e hora dos acessos, relatórios que foram
        visualizados,
      </li>
      <li>Eendereço IP das máquinas que acessaram.</li>
    </ol>

    <p>
      Os dados cadastrais são obtidos do banco de dados do Sistema e-SUS APS.
      Portanto, a única forma de cadastrar usuários e autorizar seu acesso é
      formalizando o cadastro do mesmo nesse Sistema. É por meio desses dados
      que é possível restringir e monitorar o acesso a dados pessoais dos
      cidadãos pelos USUÁRIOS, visando garantir que ocorram conforme previsto
      neste Termo e na legislação.
    </p>
    <p>
      Os dados pessoais dos usuários são enviados a LICENCIANTE com as seguintes
      finalidades:
    </p>

    <ol>
      <li>Possibilitar o funcionamento do Suporte Online.</li>
      <li>
        Permitir elaboração de relatórios de uso do Painel e-SUS APS para o
        Ministério da Saúde.
      </li>
      <li>
        Possibilitar a realização de pesquisas de usabilidade, satisfação e
        opinião dos usuários, visando o aprimoramento do sistema.
      </li>
      <li>
        Possibilitar a realização de pesquisas de Saúde Pública, mediante
        anuência da Coordenação Geral de Informação da Atenção Primária do
        Ministério da Saúde e aprovação em comitê de ética em pesquisa.
      </li>
    </ol>

    <p>
      A cada acesso ao Painel e-SUS APS, o usuário autoriza a coleta e
      tratamento de seus dados pessoais com essas finalidades.
    </p>

    <h2>Cancelamento da conta de USUÁRIO:</h2>
    <p>O cancelamento da conta de usuário poderá ser realizado:</p>

    <ol type="a">
      <li>
        pela inativação do usuário na instalação local do Sistema e-SUS APS;
      </li>
      <li>
        por iniciativa da LICENCIANTE por motivos administrativos, de auditoria
        ou segurança da informação, entre outros;
      </li>
      <li>quando solicitado pelo titular, via suporte online.</li>
    </ol>

    <p>
      Ao solicitar o cancelamento da conta, o usuário solicita também o bloqueio
      do tratamento subsequente dos dados pessoais. Os dados pessoais já
      coletados e tratados, contudo, constituem arquivo público e por isso não
      podem ser excluídos.
    </p>

    <h2>Legislação e regulamentação</h2>

    <p>
      Todo USUÁRIO deve estar atento à legislação e regulamentação em relação ao
      processo de trabalho de sua categoria profissional durante o uso do
      sistema. O uso do Painel e-SUS APS não o isenta de suas responsabilidades
      sobre as ações ou sobre o registro das ações em conformidade com a
      legislação brasileira. Desta forma, o USUÁRIO é responsável pelo uso do
      sistema, observando todas as leis, decretos e regulamentos nacionais,
      estaduais e municipais aplicáveis e em vigor, as normas de segurança e
      privacidade e as políticas publicadas.
    </p>

    <p>
      Os resultados obtidos por meio do uso de qualquer ferramenta do sistema
      são de responsabilidade do USUÁRIO, inclusive aqueles decorrentes do uso
      indevido e da não adoção de processos complementares que garantam a
      segurança dos registros.
    </p>

    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="exampleCheck1" />
      <label
        class="form-check-label"
        (click)="checkTermsOfUse(termsOfUseModal)"
        for="exampleCheck1"
        style="cursor: pointer; color: #0d6efd; text-decoration: underline"
        href="#"
        >Concordo com os termos de uso</label
      >
    </div>

    <div class="form-group" style="margin-top: 50px">
      <button
        (click)="
          acceptTermsOfUse(dowloadAppModal, dowloadConcluido, termsOfUseModal)
        "
        [disabled]="!checkValue"
        style="float: right"
        name="recover-submit"
        class="btn btn-clean-purple"
        value="Trocar senha2"
      >
        Aceitar
      </button>
    </div>
  </div>
</ng-template>

<ng-template #verifyAccount let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">
      Registro concluído com sucesso.
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" style="text-align: justify">
    <div class="row" style="margin-bottom: 20px">
      <div class="col-md-4 ms-auto"></div>
      <div class="col-md-4 ms-auto" style="flex: auto; padding-left: 25px">
        <img
          src="https://cdn-icons-png.flaticon.com/512/2919/2919894.png"
          width="125"
          height="120"
          style="display: block; border: 0px"
        />
      </div>
      <div class="col-md-4 ms-auto"></div>
    </div>
    <p>
      Seu registro foi concluído com sucesso. Enviamos um link de verificação de
      conta no seu email. Antes de fazer o download do
      <strong>PAINÉIS e-SUS APS</strong> é necessário realizar o procedimento de
      verificação de conta. O link enviado tem um prazo de validade de 30
      minutos. Por favor, realize o processo de verificação no tempo estipulado.
    </p>
    <div class="form-group" style="margin-top: 50px">
      <button
        (click)="modal.dismiss('Cross click')"
        name="recover-submit"
        class="btn btn-clean-purple"
        value="Trocar senha2"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
