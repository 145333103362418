import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private currentMenu = new ReplaySubject(1);
  constructor() {
    this.currentMenu.next( 'home' );
  }

  getCurrentMenu() : Observable<any> {
    return this.currentMenu.asObservable();
  }
  next( str: any ): void{
    this.currentMenu.next( str );
  }

}
