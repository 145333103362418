<section class="sobre b-white p-relative">
  <div class="container container-xl p-b-70">
    <div class="row p-t-120">
      <div class="col-lg-7 col-md-7 col-sm-12">
        <h2 class="title-blue" style="max-width: 706px; font-size: 58px">
          Sobre o projeto Painel Saude Fiocruz -
          <strong>Atenção Primária</strong>
        </h2>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-12">
        <img src="assets/imgs/aps.png" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="text-grey m-t-80">
          O Painel Saúde Fiocruz - Atenção Primária foi desenvolvido pela
          Fundação Oswaldo Cruz ao longo dos últimos 4 anos, com participação de
          diversas unidades e apoio do Programa Inova Fiocruz e da Secretaria de
          Atenção Primária à Saúde do Ministério da Saúde.
        </p>
        <p class="text-grey m-t-30">
          Desenhado originalmente a partir da experiência do Projeto Plataforma
          mACS/Programa Inova Fiocruz, o objetivo do Painel é oferecer aos
          municípios uma ferramenta para qualificação da Atenção à Saúde.
        </p>
        <p class="text-grey m-t-30">
          Foi inspirado em dezenas de sistemas de informação paralelos que os
          profissionais de saúde utilizam no dia a dia: cadernetas, formulários
          em papel, planilhas de Excel e Google Drive.
        </p>

        <p class="text-grey m-t-30">
          A principal inovação consiste em utilizar métodos de ciência de dados
          que utilizamos em nossas pesquisas na Fiocruz para possibilitar às
          próprias equipes de atenção primária a avaliação de como está a saúde
          da população atendida, e onde é preciso concentrar esforços, atingindo
          assim uma maior performance da atenção primária.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="sobre-painel b-r-gradiente-new p-b-100 p-relative">
  <div class="white-bottom-triangule-left"></div>
  <div class="container container-xl">
    <div class="row p-t-140">
      <div class="col-md-12">
        <h2 class="titulo white">DESENVOLVIMENTOS FUTUROS</h2>
      </div>
    </div>
    <div class="row m-t-40">
      <div class="col-md-12">
        <p class="box-text white m-t-40">
          Os projetos que financiaram o desenvolvimento do Painel Saúde Fiocruz
          já estão encerrados. Agradecemos o apoio do Campus Virtual Fiocruz,
          que tornou possível disseminar essa tecnologia educacional aberta para
          o SUS.
        </p>

        <p class="box-text white m-t-40">
          Infelizmente, não temos recursos suficientes no momento para investir
          em suporte, correção de bugs e novas funcionalidades. Estamos buscando
          parcerias e apoios para dar continuidade ao desenvolvimento dessa
          inovação para o SUS.
        </p>

        <p class="box-text white m-t-40">
          Os próximos relatórios que estão “no forno” são de acompanhamento do
          crescimento e desenvolvimento de crianças, vigilância de surtos de
          doenças contagiosas agudas e rastreamento de doenças raras.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="sobre-painel b-white p-b-100 p-relative">
  <div class="container container-xl m-b-90">
    <div class="row">
      <div class="col-md-12">
        <h2 class="titulo title-blue p-t-100">CRÉDITOS</h2>

        <p class="parceiros-default text-grey m-t-55">
          Diversas unidades e programas da Fiocruz participaram do
          desenvolvimento do Painel Saúde Fiocruz - Atenção Primária.
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <strong>Gerência Regional de Brasília</strong>
        </p>

        <p class="parceiros-default text-grey m-t-30">
          Sedia os projetos de pesquisa que embasaram o desenvolvimento do
          Painel
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <u
            >Processo de trabalho das equipes de Atenção Primária à Saúde e uso
            da informação do e-SUS AB para gestão da clínica e do cuidado</u
          >, liderado por Renata Bernardes David - CAAE: 20085119.0.0000.8027
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <u
            >Vigilância Digital em Saúde para COVID-19 a partir de prontuários
            eletrônicos </u
          >, liderado por Vinícius de Araújo Oliveira e Manoel Barral-Netto.
          CAAE: 31735320.1.0000.8027
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <strong>Instituto Gonçalo Moniz - Fiocruz Bahia</strong>
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <u
            >Centro de Integração de Dados e Conhecimentos em Saúde (CIDACS) -
          </u>
        </p>

        <p class="parceiros-default text-grey m-t-30">
          Sediou o projeto Plataforma mACS, o workshop de encerramento do
          Projeto Painéis e-SUS APS e é o Laboratório de atuação do pesquisador
          Vinícius de Araújo Oliveira.
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <u>Laboratório de de Inflamação e Biomarcadores (LIB)</u>
        </p>

        <p class="parceiros-default text-grey m-t-30">
          Sede do Projeto Vigilância Digital da Covid-19, coordenado por Manoel
          Barral-Netto, apoiado pelo Programa Fazer o Bem faz Bem da JBS S.A.
        </p>

        <p class="parceiros-default text-grey m-t-30">
          <strong
            >Vice Presidência de Ensino Informação e Comunicação (VPEIC)</strong
          >
        </p>

        <p class="parceiros-default text-grey m-t-30">
            O desenvolvimento do software e a pesquisa de campo foram parcialmente executados como atividade do Projeto Monitoramento e Avaliação das ações estratégicas dos Programas de Provimento e Formação em Saúde do Ministério da Saúde (TED) TED 09/2018 com a Secretaria de Atenção Primária à Saúde do Ministério da Saúde, coordenado por Fábio Lamin e Valéria Morgana
        </p>

        <p class="parceiros-default text-grey m-t-30">
            Campus Virtual Fiocruz tornou possível a publicação deste website e a disseminação do Painel por meio do Educare - ecossistema de Recursos Educacionais Abertos.
        </p>
      </div>
    </div>
  </div>
  <div class="white-triangule-left grey"></div>
</section>

<section class="b-gray p-b-100">
  <div class="container container-xl">
    <div class="row">
      <div class="col-md-12" style="max-width: 1131px; margin: 0 auto">
        <h2 class="titulo title-blue left">EQUIPE</h2>

        <p class="parceiros left m-t-55">Coordenação do projeto</p>
        <p class="equipe left">
            Vinícius de Araújo Oliveira, médico sanitarista, tecnologista em saúde pública, CIDACS / Fiocruz Bahia
        </p>

        <p></p>
        <p class="parceiros left m-t-55">Pesquisa: experiência do usuário e tradução do conhecimento</p>
        <p class="equipe left">
            <u>Coordenação:</u> Renata Bernardes David, fisioterapeuta, sanitarista - Fiocruz Brasília
        </p>
        <p></p>
        <br />

        <p class="equipe left">Daniela Fontinelle Botelho, especialista em UI e UX - Fiocruz Brasília</p>
        
        <p class="equipe left">Laura Gris Mota, especialista em informação em saúde - Fiocruz Brasília</p>
        
        <p class="equipe left">Ulysses de Barros Panisset, médico sanitarista, expert em tradução do conhecimento em saúde, professor da UFMG</p>
        
        <p class="equipe left">Denise Bueno, sanitarista, pesquisadora em atenção primária e vigilância em saúde - Fiocruz Brasília</p>
        
        <p class="equipe left">Leonardo Cançado Monteiro Savassi, médico de família e comunidade, professor da UFOP</p>
        <p class="equipe left">Ana Cristina da Matta Furniel, cientista social, tecnologista em saúde pública, Campus Virtual Fiocruz</p>


        <p></p>
        <p class="parceiros left m-t-55">Equipe de tecnologia da informação</p>
        <p></p>
        <p class="equipe left">
            <u>Coordenação:</u> Luciana Dantas Soares Alves - gerente de desenvolvimento
        </p>
        <p></p>
        <br />

        <p class="equipe left">Adriano Neves de Paula e Souza, cientista da computação</p>
        <p class="equipe left">Joaquim Alves C Junior, engenheiro mecatrônico</p>
        <p class="equipe left">Leandro Brandão, cientista da computação</p>
        <p class="equipe left">Tales Mota Machado, cientista da computação, cientista de dados, UFOP</p>
        <p class="equipe left">Rosane Mendes, cientista da computação, tecnologista em saúde pública - Campus Virtual Fiocruz</p>

        <p></p>


        <p></p>
        <p class="parceiros left m-t-55">Equipe de Ciência de Dados e Estatística</p>
        <p></p>
        <p class="equipe left">
            <u>Coordenação:</u> Vinícius de Araújo Oliveira
        </p>
        <p></p>
        <br />
        <p class="equipe left">Alberto Pietro Sironi, cientista de dados, UFBA</p>
        <p class="equipe left">Tales Mota Machado, cientista da computação</p>
        <p class="equipe left">Wanessa da Silva Almeida, estatística - ICICT</p>
        <p class="equipe left">Álisson Oliveira dos Santos, médico de família e comunidade, cientista de dados, Universidade Federal de São João del UFSJ</p>
        <p class="equipe left">Ricardo Antunes Dantas de Oliveira - geógrafo, ICICT</p>
        <p class="equipe left">Carolina Carvalho - cientista social, ICICT</p>


      </div>
    </div>
  </div>
</section>
