import { Injectable} from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WhitelistModel } from '../security/model/whitelist.model';
import { IdRestClass } from '../security/decorator/base.http.rest.class.decorator';
import { BaseHttpService } from '../security/service/base.http.service';
import { TokenStoreService } from '../security/token.store.service';
import { IdRest } from '../security/decorator/base.http.rest.decorator';
/**
 * Serviço de autenticação oAuth2
 */
@Injectable()
@IdRestClass('/whitelist')
export class WhitelistService extends BaseHttpService {


  constructor(public http: HttpClient, private tokenStoreService: TokenStoreService,
              private router: Router) {
    super(http, tokenStoreService);

  }

  public save(whitelist: WhitelistModel): Observable<WhitelistModel> {
    return this.post<WhitelistModel>(whitelist);
  }

  public findAll(): Observable<WhitelistModel[]> {
    return this.get<WhitelistModel[]>([WhitelistModel]);
  }
 
  @IdRest('/{id}')
  public deleteItem(id: string): Observable<WhitelistModel> {
    return this.delete<WhitelistModel>({id: id});
  }

  @IdRest('/findByCpf/{cpf}')
  public findByCpf(cpf: string): Observable<WhitelistModel> {
    return this.get<WhitelistModel>(WhitelistModel, {cpf: cpf});
  }


}
