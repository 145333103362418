import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/security/service/auth.service';
import { UserService } from 'src/app/security/service/user.service';
import { TokenStoreService } from 'src/app/security/token.store.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public authService: AuthService, public tokenStoreService: TokenStoreService,
    public userService: UserService) { }

  ngOnInit(): void {
  }

  public login() {
    this.authService.getUserToken({username:'paineisesus@fiocruz.org.br', password:'Ykv98H4rf4ryCyW5hNyaQNaBGJgCQwN7mpFPWYem', grantType:''})
    .subscribe(token => {
      this.tokenStoreService.setToken(token);
    })
  }
}
