import { Injectable } from '@angular/core';
import { Credentials } from '../model/credentials';
import { UserModel } from '../model/user.model';
import { TokenModel } from '../model/token.model';
import { IdRestClass } from '../decorator/base.http.rest.class.decorator';
import { BaseHttpService } from './base.http.service';
import { TokenStoreService } from '../token.store.service';
import { Router } from '@angular/router';

import { RequestAuth } from '../decorator/base.http.auth.decorator';
import { IdRest } from '../decorator/base.http.rest.decorator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
/**
 * Serviço de autenticação oAuth2
 */
@Injectable()
@IdRestClass('/auth')
export class AuthService extends BaseHttpService {
  constructor(
    public http: HttpClient,
    private tokenStoreService: TokenStoreService,
    private router: Router
  ) {
    super(http, tokenStoreService);
  }

  @IdRest('/token')
  @RequestAuth(false)
  public getUserToken(credentials: Credentials): Observable<TokenModel> {
    const options = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    this.clearToken();
    return this.post<TokenModel>(
      { username: credentials.username, password: credentials.password },
      options
    );
  }

  public logout() {
    this.clearToken();
    this.router.navigate(['/login']);
  }

  public get isAuthenticate(): boolean {
    return !!this.getToken();
  }

  public getCurrentUser(): Observable<UserModel> {
    console.log('Token: ', this.getToken(true));
    return null;
    //  return this.userService.getMeWithoutCache();
  }

  public getTokenInfo(): any {
    return this.getToken(true);
  }

  public checkRoles(roles: string[]): Observable<UserModel> {
    return this.getCurrentUser().pipe(
      map((user) => {
        return user;
      })
    );
  }

  public setToken(token: any): Observable<any> {
    return Observable.create((observer: any) => {
      this.tokenStoreService.setToken(token);
      observer.next(token);
      observer.complete();
    });
  }

  private clearToken() {
    this.tokenStoreService.clearToken();
  }

  private getParams(credentials: Credentials): URLSearchParams {
    return this.convertCredentials(credentials);
  }

  private convertCredentials(clientCredentials: Credentials): URLSearchParams {
    const params = new URLSearchParams();
    params.set('username', clientCredentials.username);
    params.set('password', clientCredentials.password);
    params.set('grant_type', clientCredentials.grantType);
    return params;
  }
}
