<section class="como-usar primary-info b-gradiente-new">
  <div class="container container-xl">
    <h2 class="titulo blue p-t-50">SUPORTE</h2>

    <p class="box-text">
      Caso você não tenha conseguido solucionar o problema, encaminhe sua
      mensagem pelo formulário de suporte clicando aqui
    </p>
    <p></p>

    <form class="row g-3 small-container">
      <div class="col-md-8">
        <label for="inputname" class="form-label" style="color: white"
          >NOME</label
        >
        <input type="text" class="form-control" id="inputname" />
      </div>
      <div class="col-md-4">
        <label for="inputemail" class="form-label" style="color: white"
          >e-mail</label
        >
        <input type="email" class="form-control" id="inputemail" />
      </div>
      <div class="col-12">
        <label for="floatingTextarea2" style="color: white">ASSUNTO</label>
        <textarea
          class="form-control"
          placeholder="Descrição"
          id="floatingTextarea2"
          style="height: 100px"
        ></textarea>
      </div>

      <div class="col-6">
        <div class="" style="color: white">
          <div class="input-group mb-3">
            <input type="file" class="form-control" id="inputGroupFile02">
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- <button type="submit" class="btn btn-primary">Sign in</button> -->
        <button type="submit" class="btn btn-clean" style="float: right">
          ENVIAR
        </button>
      </div>
    </form>
  </div>
</section>
