import { Component, OnInit } from '@angular/core';
import { MenuService } from './../../services/menu.service';

declare var jQuery;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  currentMenu = 'about-us';

  constructor( private menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.next( this.currentMenu );
    jQuery(window).bind('resize', (e) => this.widthAjust());
    this.widthAjust();
  }
  widthAjust() {
    let width ;
    if ( window.innerWidth > 1300) {
        width = window.innerWidth;
    } else {
        width = Math.min( window.innerWidth, window.outerWidth) ;
    }
    jQuery('.white-triangule-right').css('border-left', `${width}px solid transparent`);
    jQuery('.white-triangule-left').css('border-right', `${width}px solid transparent`);
    jQuery('.white-bottom-triangule-right').css('border-left', `${width}px solid transparent`);
    jQuery('.white-bottom-triangule-left').css('border-right', `${width}px solid transparent`);
}


}
