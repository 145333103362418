import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from './model/token.model';

@Injectable()
export class AuthRouterManagerService {
  public static requireAuthRoutes = new Set();
  public static noAuthRoutes = new Set();
  

  public static addReuireAuthRoute(route: string): void {
    this.requireAuthRoutes.add(route);
  }

  public static addNoAuthRoute(route: string): void {
    this.noAuthRoutes.add(route);
  }

  public static getRequireAuthRoutes() {
    return this.requireAuthRoutes;
  }

  public static getNoAuthRoutes() {
    return this.noAuthRoutes;
  }
}
