export class IBaseModel {
  id?: string;
  active?: boolean;
  createdDate?: Date;
  modifiedDate?: Date;
  createdBy?: string;
  modifiedBy?: string;
}

export class BaseModel implements IBaseModel {
  public id?: string;
  public readonly active?: boolean;
  public readonly createdDate?: Date;
  public readonly modifiedDate?: Date;
  public readonly createdBy?: string;
  public readonly modifiedBy?: string;
  [key: string]: any;
}
