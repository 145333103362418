import { JwtHelperService } from '@auth0/angular-jwt';

export class TokenModel {

  public accessToken: string;
  public refreshToken: string;
  public tokenType: string;
  public expires_in: number;
  public scope: string;
  public name: string;
  public jti: string;
  public authorities: Array<string>;

  constructor(token?: any) {
    if (token) {
      this.accessToken =  token.access_token;
      this.refreshToken = token.refresh_token;
      this.tokenType =  token.token_type;
      this.expires_in =  token.expires_in;
      this.scope = token.scope;
      this.name = token.name;
      this.jti =  token.jti;
      this.authorities = token.authorities;
    }
  }

  public isExpired(): boolean {
    const jwtHelperService = new JwtHelperService();
    return jwtHelperService.isTokenExpired(this.accessToken);
  }
}
