import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TokenStoreService } from '../token.store.service';
import { AuthService } from '../service/auth.service';
import { Observable } from 'rxjs';
import { AuthRouterManagerService } from '../auth-router-manager.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { TokenModel } from '../model/token.model';

@Injectable()
export class IdHttpInterceptor implements HttpInterceptor {

  constructor(public tokenStoreService: TokenStoreService,
    protected authService: AuthService,
    protected router: Router, public authManagerService: AuthRouterManagerService) {

  }

  // TODO: colocar alertar de sessão expirada
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/users')) {
      if (this.tokenStoreService.isApplicationTokenExpired()) {
        return this.authService.getUserToken({
          username: environment.username,
          password: environment.password, grantType: 'password'
        }).pipe(switchMap((applicationToken: TokenModel) => {
          this.tokenStoreService.setApplicationToken(applicationToken);
          const dupReq = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${this.tokenStoreService.getAccessTokenApplication()}`),
          });
          return next.handle(dupReq);
        }));
      } else {
        const dupReq = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${this.tokenStoreService.getAccessTokenApplication()}`),
        });
        return next.handle(dupReq);
      }
    } else if (request.url.includes('/aws-bucket')) {
      const dupReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.tokenStoreService.getAccessToken()}`),
      });
      return next.handle(dupReq);
    }

    return next.handle(request);
  }



}
