import { BaseModel } from './base.model';
import { AuthorityModel } from './authority.model';
import { UnidadeBasicaSaudeModel } from './unidade.basica.saude.model';

export class UserModel extends BaseModel {

  public name: string;
  public email: string;
  public documentNumber: string;
  public password: string;
  public phoneNumber: string;
  public cns?: string;
  public codProfissao?: string;
  public municipio?: string;
  public codIbge?: string; 
  public unidadesBasicas?: UnidadeBasicaSaudeModel;
  public codUnidade?: string;
  public downloadAllowed?: boolean;
  public showSerial?: boolean;

}
