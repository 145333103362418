<section class="pq-usar">
  <div class="container container-xl">
    <div class="row">
      <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <h2 class="title-blue m-t-30">
          <strong>DOWNLOAD</strong>
        </h2>
        <p>
          Faça o download da versão mais atual do Painel Saúde Fiocruz- v 5.2
          (Windows). Para fazer o download é preciso ser um usuário cadastrado e
          verificado como profissional de saúde.
        </p>

        <div class="row m-t-50">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <button class="btn btn-manual-grey">DOWNLOAD</button>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <button class="btn btn-manual-white">MANUAL DE INSTALAÇÃO</button>
          </div>
        </div>
      </div>
      <div
        class="col-lg-7 col-md-7 col-sm-12 col-xs-12 m-t-35"
        style="padding: 0"
      >
        <img
          class="computador-banner sobre"
          src="assets/imgs/AboutSoftware1.png"
          alt=""
        />
      </div>
    </div>
  </div>
</section>
<section class="como-usar primary-info b-gradiente-new">
  <div class="white-bottom-triangule-right"></div>
  <div class="container container-xl">
    <h2 class="titulo blue p-t-50">COMO USAR</h2>

    <p class="box-text">
      Após o download, execute o instalador e confirme para o Windows que é um
      software confiável. Você deve estar na mesma rede local de computadores
      onde está o e-SUS APS (UBS ou Municipal), informar os dados de conexão,
      clicar em “Inicializar o painel” e aguardar alguns minutos. Quando
      concluído o painel estará disponível para consulta.
    </p>

    <p class="box-text m-t-30">
      Para que os relatórios sejam atualizados de forma automática, recomendamos
      que o computador não seja desligado.
    </p>

    <p class="box-text m-t-30">
      Em caso de dúvidas consulte o Manual de Instalação.
    </p>

    <div class="row m-t-30">
      <div class="col-lg-12">
        <button class="btn btn-clean">MANUAL DO USUÁRIO</button>
      </div>
    </div>
  </div>
</section>

<section class="como-funciona b-white p-b-60">
  <div class="container container-xl">
    <h2 class="titulo title-blue p-t-90">COMO FUNCIONA</h2>
    <p class="box-text title-blue">
      A partir da conexão com o e-SUS APS, o Painel Saúde Fiocruz extrai os
      dados de cadastros e atendimentos realizados nas Unidades Básicas de Saúde
      (UBS) do município para gerar 4 relatórios:
    </p>

    <div class="row title-blue">
      <div class="lista">
        <div class="number">1.</div>
        <div class="texto">Perfil demográfico da população cadastrada</div>
      </div>
      <div class="lista">
        <div class="number">2.</div>
        <div class="texto">Painel de acompanhamentos de gestantes.</div>
      </div>
      <div class="lista">
        <div class="number">3.</div>
        <div class="texto">
          Painel de acompanhamento de pessoas com hipertensão e diabetes.
        </div>
      </div>
      <div class="lista">
        <div class="number">4.</div>
        <div class="texto">
          Alerta de surtos de doenças infecciosas agudas (em desenvolvimento)
        </div>
      </div>
    </div>

    <p class="box-text m-t-40">
      Os relatórios foram desenhados para apoiar as equipes de atenção primária
      e a coordenação distrital e municipal da APS a identificar pacientes a
      serem priorizados na busca ativa, consultas e visitas domiciliares.
    </p>
  </div>
</section>

<section class="title-purple primary-info b-grey">
  <div class="container container-xl">
    <h2 class="titulo">USO DE DADOS PESSOAIS</h2>

    <p class="box-text m-t-30">
      O painel utiliza dados pessoais sensíveis dos cidadãos atendidos para
      gerar os relatórios. Por isso só deve ser utilizado por profissionais de
      saúde em exercício, que já tenham autorização para acesso a esses dados em
      seu próprio município.
    </p>

    <p class="box-text m-t-30">
      Por isso o software só funciona com a autorização pela autoridade
      sanitária responsável pelos dados e com apoio da equipe de tecnologia da
      informação local.
    </p>

    <p class="box-text m-t-30">
      Os dados pessoais coletados referem-se apenas aos usuários ativos, para
      finalidade de aprimoramento do próprio software e para vigilância de
      potenciais usos indevidos.
    </p>

    <p class="box-text m-t-45">
      Nenhum dado pessoal é transferido para Fiocruz. Para maiores informações
      consulte a política de privacidade do Painel Saúde e a política de
      segurança da informação da Fiocruz.
    </p>

    <div class="row m-t-30">
      <div class="col-lg-12">
        <button class="btn btn-clean-purple">POLÍTICA DE PRIVACIDADE</button>
      </div>
    </div>
  </div>
  <div class="white-triangule-left"></div>
</section>

<section class="info b-white p-relative p-b-100">
  <div class="container container-xl">
    <div class="row geracao-graficos">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <h2 class="box-text-full align-right titulo purple">
          PERFIL DEMOGRÁFICO
        </h2>
        <p class="box-text-full align-right purple" style="text-align: right">
          Este relatório é gerado para o município inteiro e também por cada UBS
          separadamente contendo informações sobre cidadãos cadastrados,
          população estimada, pirâmide etária, gestantes e pessoas com
          hipertensão e diabetes, em contexto rural e urbano.
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <img src="assets/imgs/AboutSoftware2.png" class="computador-banner" alt="" />
      </div>
    </div>
    <div class="row alerta-surto">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <img src="assets/imgs/AboutSoftware3.png" class="computador-banner" alt="" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <h2 class="box-text-full align-left titulo purple">
          RELATÓRIOS DE ACOMPANHAMENTO
        </h2>
        <p class="align-left box-text-full purple">
          Gestantes e pessoas com hipertensão e diabetes têm seus dados
          organizados em um relatório que permite avaliar a cobertura das ações
          preventivas e terapêuticas preconizadas, incluindo os indicadores
          previstos no Previne Brasil.
        </p>
      </div>
    </div>
  </div>
  <div class="white-triangule-right grey"></div>
</section>

<section class="manuais p-relative p-b-125">
  <div class="container container-xl">
    <h2 class="titulo m-t-20">RELATÓRIOS INDIVIDUALIZADOS</h2>

    <p class="box-text purple">
      A partir dos relatórios de acompanhamento é possível gerar relatórios
      detalhados com todos os indivíduos priorizados. Assim será possível não
      apenas conhecer o indicador, mas também as pessoas que precisam de
      prioridade nas próximas consultas e visitas domiciliares.
    </p>

    <div class="row m-t-70">
      <div class="col-md-12">
        <img src="assets/imgs/AboutSoftware4.png" class="computador-painel" alt="" />
      </div>
    </div>
  </div>
</section>

<section class="perguntas-frequentes b-white p-b-60">
  <div class="container container-xl small-container">
    <h2 class="titulo title-blue p-t-90">PERGUNTAS FREQUENTES</h2>

    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header border rounded" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Como faço para instalar o Painel e-SUS COVID-19?
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse show ms-5 ps-5 rounded"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body rounded-2">
            <ul>
              <li>
                Primeiramente, cadastre-se e valide seu e-mail de contato. Se
                você for um profissional de saúde já cadastrado no CNES a
                liberação será automática. Caso contrário aguarde 48hs para
                liberação manual pela nossa equipe de suporte.
              </li>
              <li>
                Faça o download do instalador. Confirme para o Windows que é um
                download seguro.
              </li>
              <li>
                Para instalar abra o arquivo “installer Painel Saúde
                Fiocruz.msi” e siga as instruções na tela, sempre confirmando
                para o Windows que é um software seguro.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header border rounded" id="headingTwo">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Quais as especificações mínimas do computador para instalação e
            execução do software?
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse ms-5"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body rounded-2">
            A especificação mínima recomendada é um microcomputador com 8GB de
            RAM, Windows 10 atualizado, 30 GB de espaço livre em disco -
            preferencialmente SSD.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header border rounded" id="headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Quais os sistemas operacionais suportados?
          </button>
        </h2>
        <div
          id="collapseThree"
          class="accordion-collapse collapse ms-5 ps-5"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body rounded-2">
            <ul>
              <li>Windows 7 ou superior.</li>
              <li>
                O suporte para Linux e MacOS está previsto utilizando a
                tecnologia Docker.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header border rounded" id="headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            Como consigo os dados de configuração para conexão com o e-SUS APS?
          </button>
        </h2>
        <div
          id="collapseFour"
          class="accordion-collapse collapse ms-5 ps-5"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body rounded-2">
            O Painel já vem configurado para acessar o banco de dados local, ou
            seja irá funcionar se instalado diretamente no mesmo computador em
            que já roda o e-SUS, caso esteja com a configuração padrão. Em caso
            de dúvidas procure a equipe de tecnologia da informação do seu
            município.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header border rounded" id="headingThree">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            Quais sistemas gerenciadores de bancos de dados suportados?
          </button>
        </h2>
        <div
          id="collapseFive"
          class="accordion-collapse collapse ms-5 ps-5"
          aria-labelledby="headingThree"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body rounded-2">
            O e-SUS é compatível com PostGreSQL e Oracle. No momento suportamos
            apenas conexão com PostGreSQL.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="como-usar suporte primary-info b-gradiente-new">
  <div class="white-bottom-triangule-right"></div>
  <div class="container container-xl">
    <h2 class="titulo blue p-t-50">SUPORTE</h2>

    <p class="box-text">
      Caso você não tenha conseguido solucionar o problema, encaminhe sua
      mensagem pelo formulário de suporte clicando aqui
    </p>

    <form class="row g-3 small-container">
      <div class="col-md-8">
        <label for="inputname" class="form-label" style="color: white"
          >NOME</label
        >
        <input type="text" class="form-control" id="inputname" />
      </div>
      <div class="col-md-4">
        <label for="inputemail" class="form-label" style="color: white"
          >e-mail</label
        >
        <input type="email" class="form-control" id="inputemail" />
      </div>
      <div class="col-12">
        <label for="floatingTextarea2" style="color: white">ASSUNTO</label>
        <textarea
          class="form-control"
          placeholder="Descrição"
          id="floatingTextarea2"
          style="height: 100px"
        ></textarea>
      </div>

      <div class="col-6">
        <div class="" style="color: white">
          <div class="input-group mb-3">
            <input type="file" class="form-control" id="inputGroupFile02" />
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- <button type="submit" class="btn btn-primary">Sign in</button> -->
        <button type="submit" class="btn btn-clean" style="float: right">
          ENVIAR
        </button>
      </div>
    </form>
  </div>
</section>

<section class="sobre p-t-30 p-b-70">
  <div class="container container-xl">
    <h2 class="titulo title-blue p-t-30">SOBRE O SOFTWARE</h2>
    <p class="box-text m-t-30">
      O Painel Saúde Fiocruz Atenção Primária é um recurso educacional aberto desenvolvido pela Fundação Oswaldo Cruz e oferecido por meio do Campus Virtual Fiocruz.
    </p>

    <p class="box-text m-t-30">
      Foram utilizadas apenas tecnologias abertas para seu desenvolvimento, como Python, Pandas, Flask e Apache e-charts.
    </p>

    <p class="box-text m-t-30">
      O software está licenciado sob a licença GPL, o que significa que seu uso, adaptação e desenvolvimento de versões derivadas está liberado para todos, desde que os produtos sigam como software livre. Empresas de informática interessadas em estabelecer parcerias para integração com softwares proprietários para atenção primária devem entrar em contato via o formulário de suporte.
    </p>
  </div>
</section>
