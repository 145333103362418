import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TokenStoreService } from 'src/app/security/token.store.service';
import { MenuService } from './../../services/menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/security/service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Credentials } from 'src/app/security/model/credentials';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/security/service/user.service';
import { UserModel } from 'src/app/security/model/user.model';
import { S3Service } from 'src/app/security/service/s3.service';
import { DOCUMENT } from '@angular/common';
import { GetS3FileUrlModel } from 'src/app/security/model/get-s3-file-url.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ES6 Modules or TypeScript
import Swal from 'sweetalert2';

// CommonJS
declare var jQuery;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentMenu = 'home';
  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotPasswordForm: FormGroup;

  @ViewChild('fileInput') content: ElementRef;
  private credentials: Credentials = new Credentials();
  public username = '';
  public password = '';
  public newUser: UserModel = new UserModel();
  public doLogin = false;
  public serialNumber = '';
  public checkValue: boolean = false;

  constructor(
    private menuService: MenuService,
    private tokenStoreService: TokenStoreService,
    private modalService: NgbModal,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: any,
    private s3Service: S3Service,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.menuService.next(this.currentMenu);
    jQuery(window).bind('resize', (e) => this.widthAjust());
    this.widthAjust();
    this.createLoginValidation();
    this.createRegisterValidation();
    this.forgotPasswordFormValidation();
  }

  public checkTermsOfUse() {
    this.checkValue = !this.checkValue;
    console.log(this.checkValue);
  }

  public acceptTermsOfUse(template, serialModal,termsOfUseModal) {
    this.spinner.show();
    this.userService
      .accepttermsofuse(this.tokenStoreService.getAccessToken())
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          this.toastr.success('Termo de uso assinado com sucesso!', 'Sucesso');
          this.modalService.dismissAll(termsOfUseModal);
          this.dowloadPaineisByUrl(template, serialModal);
        },
        (error) => {
          if (error?.status === 401 || error?.status === 403) {
            this.tokenStoreService.clearToken();
            this.toastr.error(
              'Usuário não autenticado. Faça login novamente para aceitar os termos de uso.'
            );
          } else {
            this.toastr.error(
              'Não foi possível aceitar os termos de uso. Tente novamente mais tarde ou entre em contato com o suporte',
              'Erro'
            );
          }
        }
      );
  }

  createLoginValidation() {
    this.loginForm = this.fb.group({
      username: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
  }
  createRegisterValidation() {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      cpf: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ]),
      ],
      phone: ['', Validators.required],
      city: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
  }

  isValidInput(form, fieldName): boolean {
    return (
      form.controls[fieldName].invalid &&
      (form.controls[fieldName].dirty || form.controls[fieldName].touched)
    );
  }
  widthAjust() {
    let width;
    if (window.innerWidth > 1300) {
      width = window.innerWidth;
    } else {
      width = Math.min(window.innerWidth, window.outerWidth);
    }
    jQuery('.white-triangule-right').css(
      'border-left',
      `${width}px solid transparent`
    );
    jQuery('.white-triangule-left').css(
      'border-right',
      `${width}px solid transparent`
    );
  }

  downloadApp(template, serialModal, termsOfUseModal, verifyAccount) {
    const token = this.tokenStoreService.getAccessToken();
    if (!this.tokenStoreService.getAccessToken()) {
      this.modalService
        .open(template, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            console.log('SUBSCRIBE MODAL SERVICE!!!!');
          },
          (reason) => {}
        );
    } else {
      this.verifyTermsOfUse(template, serialModal, termsOfUseModal, verifyAccount);
    }
  }
  logar($e, template, serialModal, termsOfUseModal, verifyAccount) {
    $e.preventDefault();
    if (this.loginForm.valid) {
      this.login(template, serialModal, termsOfUseModal, verifyAccount);
    }
  }
  private login(template, serialModal, termsOfUseModal, verifyAccount) {
    this.spinner.show();
    this.authService
      .getUserToken({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
      })
      .pipe(
        finalize(() => {
          this.credentials = new Credentials();
          this.spinner.hide();
        })
      )
      .subscribe(
        (token) => {
          this.doLogin = true;
          this.toastr.success(
            'Login realizado com sucesso.',
            'Usuário autenticado'
          );
          this.tokenStoreService.setToken(token);
          this.verifyTermsOfUse(template, serialModal, termsOfUseModal, verifyAccount);
        },
        (error) => {
          this.toastr.error(
            'Usuário ou senha inválidos!',
            'Erro de autenticação'
          );
        }
      );
  }

  register($e, template, serialModal, termsOfUseModal, verifyAccount) {
    $e.preventDefault();
    if (this.registerForm.valid) {
      this.createNewUser(template, serialModal, termsOfUseModal, verifyAccount);
    }
  }

  private createNewUser(template, serialModal, termsOfUseModal, verifyAccount) {
    this.spinner.show();
    const userModel = this.buildUserModel();
    this.userService
      .save(userModel)
      .pipe(
        finalize(() => {
          this.newUser = new UserModel();
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          this.toastr.success(
            'Reistro realizado com sucesso.',
            'Usuário Registrado'
          );
          this.authService
            .getUserToken({
              username: userModel.email,
              password: userModel.password,
              grantType: '',
            })
            .subscribe((token) => {
              this.tokenStoreService.setToken(token);
            });

            this.modalService
            .open(verifyAccount, {
              animation: true,
              scrollable: true,
              backdrop: 'static',
              keyboard: false,
              ariaLabelledBy: 'modal-basic-title',
            })
            .result.then(
              (result) => {
                console.log('SUBSCRIBE MODAL SERVICE!!!!:', result);
              },
              (reason) => {}
            );

        },
        (error) => {
          this.toastr.error('Erro ao registrar usuário: ', 'Erro');
        }
      );
  }

  public buildUserModel(): UserModel {
    let user = new UserModel();
    user.name = this.registerForm.get('name').value;
    user.documentNumber = this.registerForm.get('cpf').value;
    user.email = this.registerForm.get('email').value;
    user.phoneNumber = this.registerForm.get('phone').value;
    user.municipio = this.registerForm.get('city').value;
    user.password = this.registerForm.get('password').value;
    return user;
  }

  public verifyTermsOfUse(template, serialModal, termsOfUseModal,verifyAccount) {
    this.spinner.show();
    this.userService
      .termsOfUse(this.tokenStoreService.getAccessToken())
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          if (result.termsOfUse) {
            this.dowloadPaineisByUrl(template, serialModal);
          } else {
            this.modalService
              .open(termsOfUseModal, {
                animation: true,
                scrollable: true,
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                ariaLabelledBy: 'modal-basic-title',
              })
              .result.then(
                (result) => {
                  console.log('SUBSCRIBE MODAL SERVICE!!!!:', result);
                },
                (reason) => {}
              );
          }
        },
        (error) => {
          if (error?.status === 401) {
            this.tokenStoreService.clearToken();
            this.toastr.error(
              'Usuário não autenticado. Faça login novamente ou registre-se para baixar o aplicativo.'
            );
            this.modalService
              .open(template, { 
                animation: true,
                scrollable: true,
                backdrop: 'static',
                keyboard: false,
                ariaLabelledBy: 'modal-basic-title'
              })
              .result.then(
                (result) => {
                  console.log('SUBSCRIBE MODAL SERVICE!!!!');
                },
                (reason) => {}
              );
          } else if (error?.status === 405) {
            Swal.fire({
              title: 'Conta não verificada!',
              text: error.error.message,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          } else {
            console.log(error);
            this.toastr.error(
              'Não foi possível iniciar o dowload do PainelCovid19. Tente novamente mais tarde ou entre em contato com o suporte',
              'Erro'
            );
          }
        }
      );
  }

  protected dowloadPaineisByUrl(template, serialModal) {
    this.spinner.show();
    this.s3Service
      .getDownloadableUrl()
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: GetS3FileUrlModel) => {
          this.document.location.href = result.url;
          this.doLogin = false;
          this.toastr.success(
            'O download começará automaticamente!',
            'Usuário autenticado'
          );
          if (result.serial.firstTime) {
            this.serialNumber = result.serial.serial;
            this.modalService
              .open(serialModal, { ariaLabelledBy: 'modal-basic-title' })
              .result.then(
                (result) => {
                  console.log('SUBSCRIBE MODAL SERVICE!!!!');
                },
                (reason) => {}
              );
          }
        },
        (error) => {
          if (error?.status === 401) {
            this.tokenStoreService.clearToken();
            this.toastr.error(
              'Usuário não autenticado. Faça login novamente ou registre-se para baixar o aplicativo.'
            );
            this.modalService
              .open(template, { ariaLabelledBy: 'modal-basic-title' })
              .result.then(
                (result) => {
                  console.log('SUBSCRIBE MODAL SERVICE!!!!');
                },
                (reason) => {}
              );
          } else if (error?.status === 403) {
            Swal.fire({
              title: 'Oops',
              text: 'Desculpe-nos o transtorno mas o seu usuário ainda não está liberado para fazer download do Painéis e-SUS APS. Tente novamente mais tarde!',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          } else if (error?.status === 405) {
            Swal.fire({
              title: 'Conta não verificada!',
              text: error.error.message,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          } else {
            console.log(error);
            this.toastr.error(
              'Não foi possível iniciar o dowload do PainelCovid19. Tente novamente mais tarde ou entre em contato com o suporte',
              'Erro'
            );
          }
        }
      );
  }

  forgotPassword(template1, template2) {
    this.modalService.dismissAll(template1);

    this.modalService
      .open(template2, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          console.log('SUBSCRIBE MODAL SERVICE!!!!');
        },
        (reason) => {}
      );
  }

  forgotPasswordFormValidation() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
    });
  }

  submitForgotPassword($e, template) {
    $e.preventDefault();
    if (this.forgotPasswordForm.valid) {
      this.spinner.show();
      this.userService
        .fogotPassword(this.forgotPasswordForm.get('email').value)
        .pipe(
          finalize(() => {
            this.spinner.hide();
            this.modalService.dismissAll(template);
          })
        )
        .subscribe(
          (result) => {
            console.log('RESULT: ', result);
            this.toastr.success(
              'A recuperação de senha foi enviada com sucesso! Fique atento ao seu email para seguir os próximos passos.',
              'Recupreação de senha enviada:'
            );
          },
          (error) => {
            console.log('-------Erro: ', error);
            if (error?.status === 400) {
              this.toastr.error(
                'Este email não está relacioado com um usuário válido!',
                'Erro'
              );
            } else {
              this.toastr.error(
                'Não foi possível enviar o email de recuperação de senha para o usuário informado',
                'Erro'
              );
            }
          }
        );
    }
  }
}
