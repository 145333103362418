import { MenuService } from './../../services/menu.service';
import { Component, OnInit } from '@angular/core';
import { WhitelistModel } from 'src/app/security/model/whitelist.model';
import { WhitelistService } from 'src/app/services/whitelist.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/security/service/user.service';
import { UserModel } from 'src/app/security/model/user.model';
import { TokenStoreService } from 'src/app/security/token.store.service';
import { PainelServiece } from 'src/app/security/service/painel.service';

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.scss']
})
export class BackofficeComponent implements OnInit {
  currentMenu = 'backoffice';

  public whiteList: WhitelistModel[];
  public showBackoffice = false;
  public newElement = new WhitelistModel();
  public searchEmail = '';
  public searchCpf = '';
  public users: UserModel[] = [];
  constructor(private menuService: MenuService, private userService: UserService, private toastr: ToastrService,
    private spinner: NgxSpinnerService, private tokenStoreService: TokenStoreService,
    private painelService: PainelServiece) { 

  }

  ngOnInit(): void {
    this.spinner.show();
    this.menuService.next(this.currentMenu);
    this.userService.findAll(this.tokenStoreService.getAccessToken())
    .pipe(
      finalize(() => {
        this.spinner.hide();
      })
    )
    .subscribe(result => {
      this.users = result;
    }, err => {
      this.spinner.hide();
    });
  }

  sendSerial(user: UserModel) {
    this.spinner.show();
    this.painelService.sendNewSerial(user._id.toString(), user.email, this.tokenStoreService.getAccessToken())
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(result => {
        this.users = result;
        this.toastr.success('Serial enviado com sucesso', 'Sucesso');
        this.ngOnInit();
      }, err => {
        this.spinner.hide();
      });
  }

  allowDownload(user: UserModel) {
    this.spinner.show();
    this.userService.allowDownload(user._id.toString(), user.downloadAllowed ? !user.downloadAllowed : true, this.tokenStoreService.getAccessToken())
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(result => {
        this.users = result;
        this.toastr.success('Permissão alterada com sucesso!', 'Sucesso');
        this.ngOnInit();
      }, err => {
        this.spinner.hide();
      });
  }

  search() {
    this.spinner.show();
    if(!this.searchEmail && !this.searchCpf) {
      this.userService.findAll(this.tokenStoreService.getAccessToken())
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(result => {
        this.users = result;
      }, err => {
        this.spinner.hide();
      });
    } else {
      this.userService.findByFilters(this.tokenStoreService.getAccessToken(), this.searchEmail, this.searchCpf)
      .subscribe((result) => {
        this.spinner.hide();
        this.users = result;
      }, err => {
        this.spinner.hide();
        this.searchEmail = '';
        this.searchCpf = '';
      })
    }
  }

  getAllowText(user: UserModel) {
    console.log("user allowed? ", user);
    return user?.downloadAllowed ? 'Bloquear download' : 'Liberar download';
  }
}
