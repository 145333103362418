import { MenuService } from '../../services/menu.service';
import { Component, OnInit } from '@angular/core';
import { WhitelistService } from 'src/app/services/whitelist.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/security/service/user.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
})
export class RecoveryPasswordComponent implements OnInit {
  currentMenu = 'recovery';
  public token: string;
  resetPasswordForm: FormGroup;

  constructor(
    private menuService: MenuService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder
  ) {
    // this.route.params.subscribe( params => params[<any parameter name>]);
  }

  ngOnInit(): void {
    this.menuService.next(this.currentMenu);
    this.resetPasswordFormValidation();
    this.route.queryParams.subscribe((params) => {
      console.log('PARAMS: ', params);
      this.token = params['token'];
      if (!this.token) {
        this.toastr.warning(
          'Acesse o link enviado ao seu email ou efetue um novo procedimento para recuperação de senha!',
          'Usuário não autorizado'
        );
        this.router.navigate(['']);
      }
    });
  }

  resetPasswordFormValidation() {
    this.resetPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      confirmPassword: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
  }

  isValidInput(form, fieldName): boolean {
    return (
      form.controls[fieldName].invalid &&
      (form.controls[fieldName].dirty || form.controls[fieldName].touched)
    );
  }

  submitResetPassword($e) {
    if (this.token) {
      this.spinner.show();
      this.userService
        .reetPassword(
          this.resetPasswordForm.get('email').value,
          this.resetPasswordForm.get('password').value,
          this.token
        )
        .pipe(
          finalize(() => {
            this.spinner.hide();
            this.router.navigate(['']);
          })
        )
        .subscribe(
          (result) => {
            this.toastr.success(
              'A recuperação de senha foi efetuada com sucesso. Faça login novamente para baixar o Painel e-SUS APS.',
              'Sucesso'
            );
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              console.log('-------->401');
              this.toastr.error(error.error.message, 'Erro!');
            } else if (error.status === '403') {
              this.toastr.error(
                'Não foi possível efetuar o procedimento de recuperação de senha. Usuário não permitido!',
                'Erro!'
              );
            } else {
              this.toastr.error(
                'Efetue o procedimento de recuepração de senha novamente.',
                'Erro!'
              );
            }
          }
        );
    }
  }
}
