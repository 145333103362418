import { MenuService } from './services/menu.service';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutSoftwareComponent } from './pages/about-software/about-software.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AuthService } from './security/service/auth.service';
import { TokenStoreService } from './security/token.store.service';
import { UserService } from './security/service/user.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdHttpInterceptor } from './security/interceptor/http.interceptor';
import { AuthRouterManagerService } from './security/auth-router-manager.service';
import { S3Service } from './security/service/s3.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { WhitelistService } from './services/whitelist.service';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { SupportComponent } from './pages/support/support.component';
import { PainelServiece } from './security/service/painel.service';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';

export let AppInjector: Injector;

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutSoftwareComponent,
    AboutUsComponent,
    ContactComponent,
    BackofficeComponent,
    RecoveryPasswordComponent,
    SupportComponent,
    VerifyAccountComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
    AuthService,
    TokenStoreService,
    UserService,
    MenuService,
    AuthRouterManagerService,
    S3Service,
    PainelServiece,
    WhitelistService,
    { provide: HTTP_INTERCEPTORS, useClass: IdHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
 }
