import { HttpParams } from '@angular/common/http';
import { AuthRouterManagerService } from '../auth-router-manager.service';

export function RequestAuth(onlyAuth: boolean): MethodDecorator {
  return (target: () => {}, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      this.requireAuthIfExists = !!onlyAuth || true;
      if(onlyAuth) {
        AuthRouterManagerService.requireAuthRoutes.add(this.methodRoute);
      } else {
        AuthRouterManagerService.noAuthRoutes.add(this.methodRoute);
      }
      const result = originalMethod.apply(this, args);
      delete this.requireAuthIfExists;
      return result;
    };

    return descriptor;
  };
}
