
<section class="sobre b-white p-relative" style="min-height: calc(100vh - 210px);">
  <div class="container-sm">
    <div class="row dflex justify-content-center">
        <div class="col-md-8 col-md-offset-4">
            <div class="panel panel-default mt-5">
              <div class="panel-body">
                <div class="text-center">
                  <h3><i class="fa fa-lock fa-2x"></i></h3>
                  <h2 class="text-center">Recuperação de senha</h2>
                  <p>Entre com seu email para a recuperação de senha.</p>
                  <div class="panel-body">
    
                    <form id="register-form" role="form" autocomplete="off" class="form" method="post"
                      [formGroup]="resetPasswordForm" (submit)="submitResetPassword($event)">

                    <div class="form-group">
                        <input type="email"
                            class="form-control"
                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entre com o email"
                            formControlName="email" name="email" required>
                        <div *ngIf="isValidInput(resetPasswordForm, 'email')" class="alert alert-danger" role="alert">
                            <p *ngIf="this.resetPasswordForm.controls['email'].errors.required">
                                O campo email é obrigatório.
                            </p>

                            <p *ngIf="this.resetPasswordForm.controls['email'].errors.email">
                               O email não é válido.
                            </p>

                        </div>
                    </div>

                    <div class="form-group">
                      <input type="password"
                          class="form-control"
                          id="password" aria-describedby="passwordHelp" placeholder="Nova senha"
                          formControlName="password" name="password" required>
                      <div *ngIf="isValidInput(resetPasswordForm, 'password')" class="alert alert-danger" role="alert">
                          <p *ngIf="this.resetPasswordForm.controls['password'].errors.required">
                              O campo nova senha é obrigatório.
                          </p>

                          <p *ngIf="this.resetPasswordForm.controls['password'].errors.email">
                             Nova senha não é válida.
                          </p>

                      </div>
                  </div>

                  <div class="form-group">
                    <input type="password"
                        class="form-control"
                        id="confirm-password" aria-describedby="passwordHelp" placeholder="Confirmar senha"
                        formControlName="confirmPassword" name="confirmPassword" required>
                    <div *ngIf="isValidInput(resetPasswordForm, 'confirmPassword')" class="alert alert-danger" role="alert">
                        <p *ngIf="this.resetPasswordForm.controls['confirmPassword'].errors.required">
                            O confirmar nova senha é obrigatório.
                        </p>

                        <p *ngIf="this.resetPasswordForm.controls['confirmPassword'].errors.email">
                           Nova senha não é válida.
                        </p>

                    </div>
                </div>
                      <div class="form-group" style="margin-top: 30px">
                        <button  name="recover-submit" class="btn btn-clean-purple" value="Trocar senha2" type="submit">Trocar senha</button>
                      </div>
                      
                      
                      <input type="hidden" class="hide" name="token" id="token" value=""> 
                    </form>
    
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
</section>