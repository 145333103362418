<section
  class="sobre b-white p-relative"
  style="min-height: calc(100vh - 210px)"
>
  <div class="container container-xl pt-5 p-b-70">
    <div class="row row-conf">
      <div class="col-12">
        <h2 class="titulo blue left">Filtro</h2>
      </div>
      <div class="col-12">
        <form>
          <div class="row">
            <div class="col-md-5 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder="Email"
                name="email"
                [(ngModel)]="searchEmail"
              />
            </div>
            <div class="col-md-5 col-sm-12">
              <input
                type="text"
                class="form-control"
                placeholder="CPF"
                name="cpf"
                [(ngModel)]="searchCpf"
                mask="000.000.000-00"
              />
            </div>
            <div class="col-md-2 col-sm-12">
              <button
                type="button"
                class="w-100 btn btn-primary btn-backoffice-add"
                (click)="search()"
              >
                <i class="fa fa-search mr-3" aria-hidden="true"></i>Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="container container-xl p-b-70">
    <div class="row">
      <div class="col-md-12">
        


        <table *ngIf="users && users.length > 0" class="table align-middle">
            <thead class="table-dark">
              <tr>
                <th scope="col" class="w-24">Nome</th>
                <th scope="col" class="w-22">Email</th>
                <th scope="col" class="w-22">Cpf</th>
                <th scope="col" class="w-22">Município</th>
                <th scope="col" class="w-10">Serial</th>
                <th scope="col" class="w-10">Download</th>
              </tr>
            </thead>
            <tbody *ngFor="let user of users">
              <tr>
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{user.documentNumber}}</td>
                <td>{{user.municipio}}</td>
                <td><span style="cursor: pointer" (click)="sendSerial(user)" class="badge bg-primary rounded-pill">Enviar serial</span></td>
                <td><span style="cursor: pointer" (click)="allowDownload(user)" [ngClass]="user.downloadAllowed ? 'badge bg-danger rounded-pill' : 'badge bg-success rounded-pill'">{{getAllowText(user)}}</span></td>
              </tr>
              
            </tbody>
          </table>
      </div>
    </div>
  </div>
</section>

<ngx-spinner></ngx-spinner>
