import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from './model/token.model';

@Injectable()
export class TokenStoreService {

  public invalidTokenRequired = new EventEmitter<boolean>();
  private OAUTH_TOKEN = 'OAUTH_TOKEN';

  private ACCESS_TOKEN = 'access_token';
  private REFRESH_TOKEN = 'refresh_token';
  private ACCESS_TOKEN_APPLICATION = 'access_token_application';
  private REFRESH_TOKEN_APPLICATION = 'refresh_token_application';
  private jwtHelperService = new JwtHelperService();

  public setToken(token: any): void {
    const tokenInfo: TokenModel = new TokenModel(token);
    localStorage.setItem(this.ACCESS_TOKEN, tokenInfo.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokenInfo.refreshToken);
  }

  public setApplicationToken(token: any): void {
    const tokenInfo: TokenModel = new TokenModel(token);
    localStorage.setItem(this.ACCESS_TOKEN_APPLICATION, tokenInfo.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN_APPLICATION, tokenInfo.refreshToken);
  }

  public getAccessToken(): string {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  public getAccessTokenApplication(): string {
    return localStorage.getItem(this.ACCESS_TOKEN_APPLICATION);
  }

  public getRefreshTokenApplication() {
    return localStorage.getItem(this.REFRESH_TOKEN_APPLICATION);
  }

  public getToken(): TokenModel {
    const strToken = localStorage.getItem(this.ACCESS_TOKEN);
    const jsonToken = JSON.parse(strToken);
    if (jsonToken) {
      const tokenInfo: TokenModel = Object.assign(new TokenModel(), jsonToken);

      if (!tokenInfo.isExpired()) {
        return tokenInfo;
      }
    }

    this.invalidTokenRequired.emit(true);
    return new TokenModel(null);
  }

  public clearToken() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(this.ACCESS_TOKEN);
      localStorage.removeItem(this.ACCESS_TOKEN);
      localStorage.removeItem(this.REFRESH_TOKEN);
      resolve();
    });
  }



  public clearTokenApplication() {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(this.ACCESS_TOKEN_APPLICATION);
      localStorage.removeItem(this.REFRESH_TOKEN_APPLICATION);
      resolve();
    });
  }
  public getTokenDecode(): any {
    return this.jwtHelperService.decodeToken(localStorage.getItem(this.ACCESS_TOKEN));
  }

  public isTokenExpired(): boolean {
    const token = this.getAccessToken();
    const res = this.jwtHelperService.isTokenExpired(token);
    return res;
  }

  public isApplicationTokenExpired(): boolean {
    const res = this.jwtHelperService.isTokenExpired(this.getAccessTokenApplication());
    return res;
  }

  public isRootUser(): any {
    console.log('IS ROOT USER?')
    const decoded = this.jwtHelperService.decodeToken(localStorage.getItem(this.ACCESS_TOKEN));
    console.log(decoded);
    return decoded.email === 'unasus01@fiotec.fiocruz.br'
  }

}
