import { Injectable} from '@angular/core';
import { IdRestClass } from '../decorator/base.http.rest.class.decorator';
import { BaseHttpService } from './base.http.service';
import { TokenStoreService } from '../token.store.service';
import { Router } from '@angular/router';
import { IdRest } from '../decorator/base.http.rest.decorator';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

/**
 * Serviço de autenticação oAuth2
 */
@Injectable()
@IdRestClass('/painel')
export class PainelServiece extends BaseHttpService {


  constructor(public http: HttpClient, private tokenStoreService: TokenStoreService,
              private router: Router) {
    super(http, tokenStoreService);

  }

  @IdRest('/serialnumber')
  public sendSerialNumberToEmail(): Observable<void> {
    return this.get<void>('');
  }


  @IdRest('/send-new-serial')
  public sendNewSerial(userId: string, email: string, token: string): Observable<any> {
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<any>({userId, email}, options);
  }

}
