import { Injectable} from '@angular/core';
import {Credentials} from '../model/credentials';
import {UserModel} from '../model/user.model';
import {TokenModel} from '../model/token.model';
import { IdRestClass } from '../decorator/base.http.rest.class.decorator';
import { BaseHttpService } from './base.http.service';
import { TokenStoreService } from '../token.store.service';
import { Router } from '@angular/router';

import { RequestAuth } from '../decorator/base.http.auth.decorator';
import { IdRest } from '../decorator/base.http.rest.decorator';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GetS3FileUrlModel } from '../model/get-s3-file-url.model';
/**
 * Serviço de autenticação oAuth2
 */
@Injectable()
@IdRestClass('/file')
export class S3Service extends BaseHttpService {


  constructor(public http: HttpClient, private tokenStoreService: TokenStoreService,
              private router: Router) {
    super(http, tokenStoreService);

  }

  @IdRest('/download/{keyname}')
  public downloadPaineis(fileName: string): Observable<any> {
    const _options: any = {
      responseType: 'blob'
    };
    return this.get<Blob>(Blob, {keyname: fileName}, _options);
  }

  // @IdRest('/getUrl')
  // public getFileUrl(keyname: string): Observable<String> {
  //   const params = {
  //     keyname: keyname
  //   };

  //   return this.get<String>(params);
  // }

  @IdRest('/byname/:filename')
  public getFileUrl(keyname: string): Observable<GetS3FileUrlModel> {
    return this.post<GetS3FileUrlModel>(keyname);
  }


  @IdRest('/presignedurl')
  public getDownloadableUrl(): Observable<GetS3FileUrlModel> {
    return this.get<GetS3FileUrlModel>(GetS3FileUrlModel);
  }

}
