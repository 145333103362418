import { Injectable} from '@angular/core';
import {UserModel} from '../model/user.model';
import { IdRestClass } from '../decorator/base.http.rest.class.decorator';
import { BaseHttpService } from './base.http.service';
import { TokenStoreService } from '../token.store.service';
import { Router } from '@angular/router';

import { RequestAuth } from '../decorator/base.http.auth.decorator';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IdRest } from '../decorator/base.http.rest.decorator';
import { VerifyAccountModel } from '../model/verifyAccount.model';
/**
 * Serviço de autenticação oAuth2
 */
@Injectable()
@IdRestClass('/user')
export class UserService extends BaseHttpService {


  constructor(public http: HttpClient, private tokenStoreService: TokenStoreService,
              private router: Router) {
    super(http, tokenStoreService);

  }

  public save(user: UserModel): Observable<UserModel> {
    return this.post<UserModel>({realm: 'painelesusbackend', user});
  }
  
  @IdRest('/me')
  public me(): Observable<UserModel> {
    return this.get<UserModel>(UserModel);
  }

  @IdRest('/findAll')
  public findAll(token): Observable<UserModel[]> {
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.get<UserModel[]>([UserModel], options);
  }

  @IdRest('/findByFilters')
  public findByFilters(token: string, email: string, cpf: string): Observable<UserModel[]> {
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<UserModel[]>({email: email, cpf: cpf}, options);
  }

  @IdRest('/forgot-password')
  public fogotPassword(email: string): Observable<UserModel> {
    console.log('FORGOT PASSWORD!!!!');
    return this.post<UserModel>({email});
  }

  @IdRest('/reset-password')
  public reetPassword(email: string, password: string, token: string): Observable<UserModel> {
    console.log('RESET PASSWORD!!!!!');
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<UserModel>({email, password}, options);
  }

  @IdRest('/verifyaccount')
  public verifyAccount(token: string): Observable<VerifyAccountModel> {
    return this.post<VerifyAccountModel>({token});
  }

  @IdRest('/termsofuse')
  public termsOfUse(token: string): Observable<any> {
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<any>(options);
  }

  @IdRest('/accepttermsofuse')
  public accepttermsofuse(token: string): Observable<any> {
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<any>(options);
  }



  @IdRest('/allowdownload')
  public allowDownload(userId: string, allow: boolean, token: string): Observable<any> {
    console.log('RESET PASSWORD!!!!!');
    const options: any = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post<any>({userId, allow}, options);
  }

}
