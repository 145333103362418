import { JwtHelperService } from '@auth0/angular-jwt';

export class GetS3FileUrlModel {

  public fileName: string;
  public url: string;
  public serial: {
    firstTime: boolean, 
    serial: string
  }
}
