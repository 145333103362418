import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuService } from './../../services/menu.service';

declare var jQuery;
@Component({
  selector: 'app-about-software',
  templateUrl: './about-software.component.html',
  styleUrls: ['./about-software.component.scss'],
})
export class AboutSoftwareComponent implements OnInit {
  currentMenu = 'about-software';
  supportForm: FormGroup;
  estados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
    'DF',
  ];
  constructor(private menuService: MenuService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.menuService.next(this.currentMenu);
    jQuery(window).bind('resize', (e) => this.widthAjust());
    this.widthAjust();
    this.createSupportValidation();
  }

  createSupportValidation() {
    this.supportForm = this.fb.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      supportText: ['', Validators.compose([Validators.required, Validators.minLength(20)])]
    });
  }

  isValidInput(form, fieldName): boolean {
    return form.controls[fieldName].invalid &&
      (form.controls[fieldName].dirty || form.controls[fieldName].touched);
  }

  sendSupport() {
    const username = this.supportForm.get('username').value;
    // const password =  this.supportForm.get('password').value;

    // console.log(username+ '    ' + password);
  }

  clearForm() {
    
  }


  widthAjust() {
    let width;
    if (window.innerWidth > 1300) {
      width = window.innerWidth;
    } else {
      width = Math.min(window.innerWidth, window.outerWidth);
    }
    jQuery('.white-triangule-right').css(
      'border-left',
      `${width}px solid transparent`
    );
    jQuery('.white-triangule-left').css(
      'border-right',
      `${width}px solid transparent`
    );
    jQuery('.white-bottom-triangule-right').css(
      'border-left',
      `${width}px solid transparent`
    );
    jQuery('.white-bottom-triangule-left').css(
      'border-right',
      `${width}px solid transparent`
    );
  }
}
