import { MenuService } from './services/menu.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TokenStoreService } from './security/token.store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './security/service/auth.service';
import { finalize, switchMap } from 'rxjs/operators';
import { Credentials } from './security/model/credentials';
import { UserModel } from './security/model/user.model';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './security/service/user.service';
import { AuthorityModel } from './security/model/authority.model';
import { PainelServiece } from './security/service/painel.service';
import { errorMonitor } from 'events';

declare var jQuery;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'paineis-download';
  currentMenu = 'about-us';
  private _currentMenu: Subscription = null;
  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotPasswordForm: FormGroup;
  private credentials: Credentials = new Credentials();
  public username = '';
  public password = '';
  public newUser: UserModel = new UserModel();
  public currentUser: UserModel = new UserModel();
  public doLogin = false;
  public showBackoffice = false;

  constructor(
    private menuService: MenuService,
    private changeRef: ChangeDetectorRef,
    public tokenStoreService: TokenStoreService,
    private modalService: NgbModal,
    private authService: AuthService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private userService: UserService,
    private painelService: PainelServiece
  ) {}

  ngOnInit(): void {
    this._currentMenu = this.menuService
      .getCurrentMenu()
      .subscribe((resp: any) => {
        this.currentMenu = resp;
        this.changeRef.detectChanges();
        jQuery(window).bind('resize', (e) => this.widthAjust());
        this.widthAjust();
        this.createLoginValidation();
        this.createRegisterValidation();
        this.forgotPasswordFormValidation();
      });
  }
  ngOnDestroy(): void {
    this._currentMenu.unsubscribe();
  }
  getClass(str: string): string {
    return this.currentMenu === str ? 'active' : '';
  }

  createLoginValidation() {
    this.loginForm = this.fb.group({
      username: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
  }
  createRegisterValidation() {
    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      cpf: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ]),
      ],
      phone: ['', Validators.required],
      city: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
  }
  isValidInput(form, fieldName): boolean {
    return (
      form.controls[fieldName].invalid &&
      (form.controls[fieldName].dirty || form.controls[fieldName].touched)
    );
  }
  widthAjust() {
    let width;
    if (window.innerWidth > 1300) {
      width = window.innerWidth;
    } else {
      width = Math.min(window.innerWidth, window.outerWidth);
    }
    jQuery('.white-triangule-right').css(
      'border-left',
      `${width}px solid transparent`
    );
    jQuery('.white-triangule-left').css(
      'border-right',
      `${width}px solid transparent`
    );
  }

  retrieveSerialNumber(template) {
    this.painelService
      .sendSerialNumberToEmail()
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          this.modalService
            .open(template, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
              (result) => {
                console.log('SUBSCRIBE MODAL SERVICE!!!!');
              },
              (reason) => {}
            );
        },
        (error) => {
          if(error.status === 400) {
            this.toastr.error(
              'Desculpe mas ainda não é possível fazer o download do número de serial. Só depois de fazer o download do PAINÉIS e-SUS APS que o serial será liberado. Se ainda não está liberado para fazer acesso, entre em contato com o suporte.',
              'Erro'
            );
          } else {
            console.log(error.status);
            this.toastr.error(
              'Erro ao recuperar serial number. Tente novamente mais tarde. ',
              'Erro'
            );
          }
        }
      );
  }
  downloadApp(template) {
    if (this.tokenStoreService.isTokenExpired()) {
      this.modalService
        .open(template, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            console.log('SUBSCRIBE MODAL SERVICE!!!!');
          },
          (reason) => {}
        );
    } else {
      console.log('ELSE');
    }
  }

  logar($e) {
    $e.preventDefault();
    if (this.loginForm.valid) {
      this.login();
    }
  }
  private login() {
    this.spinner.show();
    this.authService
      .getUserToken({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        grantType: '',
      })
      .pipe(
        finalize(() => {
          this.credentials = new Credentials();
          this.spinner.hide();
        })
      )
      .subscribe(
        (token) => {
          this.doLogin = true;
          this.toastr.success(
            'Login realizado com sucesso!',
            'Usuário autenticado'
          );
          this.tokenStoreService.setToken(token);
          this.showBackoffice = false;
          // this.verifyCanShowBackoffice();
        },
        (error) => {
          this.toastr.error(
            'Usuário ou senha inválidos!',
            'Erro de autenticação'
          );
        }
      );
  }

  public verifyCanShowBackoffice() {
    if (!this.tokenStoreService.isTokenExpired()) {
      const token = this.tokenStoreService.getTokenDecode();
      console.log(token);
      if (token?.authorities?.includes('ROOT')) {
        this.showBackoffice = true;
      }
    }
  }

  public logout() {
    this.tokenStoreService.clearToken();
    this.toastr.info('Sessão terminada!');
  }

  register($e,verifyAccount) {
    $e.preventDefault();
    if (this.registerForm.valid) {
      this.createNewUser( verifyAccount);
    }
  }

  private createNewUser(verifyAccount) {
    this.spinner.show();
    const userModel = this.buildUserModel();
    this.userService
      .save(userModel)
      .pipe(
        finalize(() => {
          this.newUser = new UserModel();
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          this.spinner.hide();
          this.toastr.success(
            'Reistro realizado com sucesso.',
            'Usuário Registrado'
          );
          this.authService
            .getUserToken({
              username: userModel.email,
              password: userModel.password,
              grantType: '',
            })
            .subscribe((token) => {
              this.tokenStoreService.setToken(token);
            });

            this.modalService
            .open(verifyAccount, {
              animation: true,
              scrollable: true,
              backdrop: 'static',
              keyboard: false,
              ariaLabelledBy: 'modal-basic-title',
            })
            .result.then(
              (result) => {
                console.log('SUBSCRIBE MODAL SERVICE!!!!:', result);
              },
              (reason) => {}
            );
        },
        (error) => {
          this.toastr.error(
            'Erro ao registrar usuário.',
            'Erro'
          );
        }
      );
  }

  public buildUserModel(): UserModel {
    let user = new UserModel();
    user.name = this.registerForm.get('name').value;
    user.documentNumber = this.registerForm.get('cpf').value;
    user.email = this.registerForm.get('email').value;
    user.phoneNumber = this.registerForm.get('phone').value;
    user.municipio = this.registerForm.get('city').value;
    user.password = this.registerForm.get('password').value;
    return user;
  }

  forgotPassword(template1, template2) {
    this.modalService.dismissAll(template1);

    this.modalService
      .open(template2, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          console.log('SUBSCRIBE MODAL SERVICE!!!!');
        },
        (reason) => {}
      );
  }

  forgotPasswordFormValidation() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
    });
  }

  submitForgotPassword($e, template) {
    $e.preventDefault();
    if (this.forgotPasswordForm.valid) {
      this.spinner.show();
      this.userService
        .fogotPassword(this.forgotPasswordForm.get('email').value)
        .pipe(
          finalize(() => {
            this.spinner.hide();
            this.modalService.dismissAll(template);
          })
        )
        .subscribe(
          (result) => {
            console.log('RESULT: ', result);
            this.toastr.success(
              'A recuperação de senha foi enviada com sucesso! Fique atento ao seu email para seguir os próximos passos.',
              'Recupreação de senha enviada:'
            );
          },
          (error) => {
            console.log('-------Erro: ', error);
            if (error?.status === 400) {
              this.toastr.error(
                'Este email não está relacioado com um usuário válido!',
                'Erro'
              );
            } else {
              this.toastr.error(
                'Não foi possível enviar o email de recuperação de senha para o usuário informado',
                'Erro'
              );
            }
          }
        );
    }
  }

  isRootUser() {
    
  }
}
