import { MenuService } from '../../services/menu.service';
import { Component, OnInit } from '@angular/core';
import { WhitelistService } from 'src/app/services/whitelist.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/security/service/user.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  currentMenu = 'verifyaccount';
  public token: string;
  resetPasswordForm: FormGroup;
  public accountVerified: number = 0;
  public errorMessage = '';
  public errorTitle = '';

  constructor(
    private menuService: MenuService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder
  ) {
    // this.route.params.subscribe( params => params[<any parameter name>]);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.menuService.next(this.currentMenu);
    this.route.queryParams
    .subscribe((params) => {
      console.log('PARAMS: ', params);
      this.token = params['token'];
      if (!this.token) {
        this.toastr.warning(
          'Acesse o link enviado ao seu email para realizar a verificação da conta.',
          'Usuário não autorizado'
        );
        this.spinner.hide();
        this.router.navigate(['']);
      } else {
        this.verifyAccount();
      }
    });
  }
  veri
  
  verifyAccount() {
    if (this.token) {
      this.spinner.show();
      this.userService
        .verifyAccount(
          this.token
        )
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result) => {
            if(result.accountVerified) {
              this.toastr.success(
                'Conta verificada com sucesso!',
                'Sucesso'
              );
              this.accountVerified = 1;
            } else {
              this.errorTitle = 'Token expirado!';
              this.errorMessage ='Não foi possível realizar o processo de validação de sua conta. O token de validação expirou o tempo máximo de 30 minutos. Um novo link de validação foi enviado ao seu email. Realize o processo de validação em no máximo 30 minutos.';
              this.accountVerified = 2;
            }
          },
          (error) => {
            this.accountVerified = 2;
            if(error?.status == 400) {
              this.errorTitle = 'Token inválido.';
              this.errorMessage = 'O token de validação enviado é inválido. Por favor, incie o processo de validação da conta clicando no link enviado ao seu email.'
            } else {
              this.errorTitle = 'Erro';
              this.errorMessage ='Houve um erro no processo de confirmação de conta. Um novo link foi enviado ao seu email. Efetue a confirmação da conta em até 30 minutos.';
            }
          }
        );
    }
  }
}
