<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="fa fa-bars" aria-hidden="true"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a
        class="nav-item nav-link"
        [ngClass]="getClass('home')"
        [routerLink]="'/'"
        >Home <span class="sr-only">(current)</span></a
      >
      <a
        class="nav-item nav-link"
        [ngClass]="getClass('about-software')"
        [routerLink]="'/about-software'"
        >Sobre o Software</a
      >

      <a
        class="nav-item nav-link"
        [ngClass]="getClass('support')"
        [routerLink]="'/support'"
        >Suporte</a
      >

      <a
        class="nav-item nav-link"
        [ngClass]="getClass('about-us')"
        [routerLink]="'/about-us'"
        >Quem somos</a
      >

      <a
        class="nav-item nav-link"
        [routerLink]="'/'"
        style="color: #54efbf"
        *ngIf="!tokenStoreService.isTokenExpired()"
        (click)="retrieveSerialNumber(retrieveSerialModal)"
        >Serial</a
      >

      <a
        class="nav-item nav-link"
        style="color: #54efbf"
        [routerLink]="'/'"
        *ngIf="tokenStoreService.isTokenExpired()"
        (click)="downloadApp(dowloadAppModal)"
        >Entrar</a
      >

      <a
      class="nav-item nav-link"
      style="color: #54efbf"
      *ngIf="!tokenStoreService.isTokenExpired() && tokenStoreService.isRootUser()"
      [ngClass]="getClass('backoffice')"
      [routerLink]="'/backoffice'"
      >Backoffice</a>

      <a
        *ngIf="!tokenStoreService.isTokenExpired()"
        style="color: #c76037"
        class="nav-item nav-link"
        [ngClass]="getClass('backoffice')"
        [routerLink]="'/'"
        (click)="logout()"
        >Sair</a>

    </div>
  </div>
</nav>

<router-outlet></router-outlet>

<ng-template #dowloadAppModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Autenticação necessária</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#login"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Entrar
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#register"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Registrar
        </button>
      </li>
    </ul>

    <!-- <ul class="nav nav-tabs nav-modal" id="myTab" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#login" role="tab" aria-controls="home">Entrar</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#register" role="tab" aria-controls="profile">Registrar</a>
          </li>
      </ul> -->

    <div class="tab-content">
      <div class="tab-pane active" id="login" role="tabpanel">
        <p></p>
        <p></p>
        <form
          class="container"
          [formGroup]="loginForm"
          (submit)="logar($event)"
        >
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control {{
                isValidInput(loginForm, 'username') ? 'is-invalid' : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com o email"
              formControlName="username"
              name="email"
              required
            />
            <div
              *ngIf="isValidInput(loginForm, 'username')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="this.loginForm.controls['username'].errors.required">
                O campo email é obrigatório.
              </p>

              <p *ngIf="this.loginForm.controls['username'].errors.email">
                O email não é válido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Senha</label>
            <input
              type="password"
              class="form-control {{
                isValidInput(loginForm, 'password') ? 'is-invalid' : 'is-valid'
              }} "
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com uma senha segura"
              name="password"
              formControlName="password"
              required
            />

            <div
              *ngIf="isValidInput(loginForm, 'password')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="this.loginForm.controls['password'].errors.required">
                A senha é obrigatória.
              </p>

              <p *ngIf="this.loginForm.controls['password'].errors.minlength">
                A senha deve ter no mínimo 4 caracteres.
              </p>
            </div>
          </div>

          <div style="margin-top: 10px" class="">
            <a
              href="#"
              (click)="forgotPassword(dowloadAppModal, forgotPasswordModal)"
              id="forgot_pswd"
              >Esqueceu a senha?</a
            >
          </div>
          <p></p>
          <button
            type="submit"
            class="btn btn-outline-dark"
            [disabled]="loginForm.invalid"
            (click)="modal.close('Entrar')"
          >
            Entrar
          </button>
        </form>
      </div>
      <div class="tab-pane" id="register" role="tabpanel">
        <p></p>
        <p></p>
        <p></p>
        <p></p>
        <form
          class="container"
          [formGroup]="registerForm"
          (submit)="register($event, verifyAccount)"
        >
          <div class="form-group">
            <label for="name">Nome</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'name') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Nome"
              name="newUserName"
              formControlName="name"
            />

            <div
              *ngIf="isValidInput(registerForm, 'name')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['name'].errors.required">
                O campo nome é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">CPF</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'cpf') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="CPF"
              name="newUserCpf"
              mask="000.000.000-00"
              formControlName="cpf"
            />
            <div
              *ngIf="isValidInput(registerForm, 'cpf')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['cpf'].errors.required">
                O campo cpf é obrigatório.
              </p>
              <p *ngIf="registerForm.controls['cpf'].errors.minlength">
                O campo cpf está inválido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Telefone</label>
            <input
              type="tel"
              class="form-control {{
                isValidInput(registerForm, 'phone') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Telefone"
              formControlName="phone"
              mask="(00) 00000-0000"
            />
            <div
              *ngIf="isValidInput(registerForm, 'phone')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['phone'].errors.required">
                O campo phone é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Município</label>
            <input
              type="text"
              class="form-control {{
                isValidInput(registerForm, 'city') ? 'is-invalid' : 'is-valid'
              }}"
              id="name"
              placeholder="Digite o nome do município"
              name="newUserMunicipio"
              formControlName="city"
            />
            <div
              *ngIf="isValidInput(registerForm, 'city')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['city'].errors.required">
                O campo município é obrigatório.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input
              type="email"
              class="form-control {{
                isValidInput(registerForm, 'email') ? 'is-invalid' : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com o email"
              name="newUserEmail"
              formControlName="email"
            />
            <small id="emailHelp" class="form-text text-muted"
              >Entre com um email válido.</small
            >
            <div
              *ngIf="isValidInput(registerForm, 'email')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['email'].errors.required">
                O campo email é obrigatório.
              </p>
              <p *ngIf="registerForm.controls['email'].errors.email">
                O campo email não é válido.
              </p>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Senha</label>
            <input
              type="password"
              class="form-control {{
                isValidInput(registerForm, 'password')
                  ? 'is-invalid'
                  : 'is-valid'
              }}"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Entre com uma senha seguira"
              name="newUserPassword"
              formControlName="password"
            />
            <small id="emailHelp" class="form-text text-muted"
              >Procure misturar letras e números para uma senha segura.</small
            >
            <div
              *ngIf="isValidInput(registerForm, 'password')"
              class="alert alert-danger"
              role="alert"
            >
              <p *ngIf="registerForm.controls['password'].errors.required">
                O campo senha é obrigatório.
              </p>
            </div>
          </div>
          <button
            style="margin-top: 20px"
            type="submit"
            class="btn btn-outline-dark"
            (click)="modal.close('Registrar')"
          >
            Registrar
          </button>
        </form>
      </div>
    </div>

    <script>
      $(function () {
        $("#myTab a:last").tab("show");
      });
    </script>
  </div>
</ng-template>

<ng-template #forgotPasswordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Esqeuceu a senha?</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-md-offset-4">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">
                <h3><i class="fa fa-lock fa-4x"></i></h3>
                <h2 class="text-center">Recuperação de senha</h2>
                <p>Entre com seu email para a recuperação de senha.</p>
                <div class="panel-body">
                  <form
                    id="register-form"
                    role="form"
                    autocomplete="off"
                    class="form"
                    method="post"
                    [formGroup]="forgotPasswordForm"
                    (submit)="submitForgotPassword($event, forgotPasswordModal)"
                  >
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email</label>
                      <input
                        type="email"
                        class="form-control {{
                          isValidInput(forgotPasswordForm, 'email')
                            ? 'is-invalid'
                            : 'is-valid'
                        }}"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Entre com o email"
                        formControlName="email"
                        name="email"
                        required
                      />
                      <div
                        *ngIf="isValidInput(forgotPasswordForm, 'email')"
                        class="alert alert-danger"
                        role="alert"
                      >
                        <p
                          *ngIf="
                            this.forgotPasswordForm.controls['email'].errors
                              .required
                          "
                        >
                          O campo email é obrigatório.
                        </p>

                        <p
                          *ngIf="
                            this.forgotPasswordForm.controls['email'].errors
                              .email
                          "
                        >
                          O email não é válido.
                        </p>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                                <div class="input-group">
                                  <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                                  <input id="email" name="email" placeholder="email" class="form-control"  type="email">
                                </div>
                              </div> -->
                    <div class="form-group" style="margin-top: 30px">
                      <button
                        name="recover-submit"
                        class="btn btn-clean-purple"
                        value="Trocar senha2"
                        type="submit"
                      >
                        Enviar email
                      </button>
                    </div>

                    <input
                      type="hidden"
                      class="hide"
                      name="token"
                      id="token"
                      value=""
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #retrieveSerialModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Número serial</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-md-offset-4">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="text-center">
                <h3><i class="fa fa-check-circle-o fa-4x" style="color: rgb(43, 214, 177);"></i></h3>
                <h2 class="text-center">Sucesso!</h2>
                <p>Recuperação de serial realizada com sucesso. O número de serial foi enviado para o seu email.</p>
                <div class="panel-body">
                                      <div class="form-group" style="margin-top: 30px">
                      <!-- <input name="recover-submit" class="btn btn-lg btn-primary btn-block" value="Enviar email" type="submit"> -->
                      <button
                        name="recover-submit"
                        class="btn btn-clean-purple"
                        value="Trocar senha2"
                        type="submit"
                        (click)="modal.dismiss('Cross click')"
                      >
                        Ok
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #verifyAccount let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Registro concluído com sucesso.</h4>
    <button
      type="button"
      class="btn-close"
      aria-describedby="modal-title"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body" style="text-align: justify">
    <div class="row" style="margin-bottom: 20px;">
      <div class="col-md-4 ms-auto"></div>
      <div class="col-md-4 ms-auto" style="flex: auto;padding-left: 25px;">
        <img
          src="https://cdn-icons-png.flaticon.com/512/2919/2919894.png"
          width="125"
          height="120"
          style="display: block; border: 0px"
        />
      </div>
      <div class="col-md-4 ms-auto"></div>
    </div>
    <p>Seu registro foi concluído com sucesso. Enviamos um link de verificação de conta no seu email.
    Antes de fazer o download do <strong>PAINÉIS e-SUS APS</strong> é necessário realizar o procedimento de verificação de conta.
    O link enviado tem um prazo de validade de 30 minutos. Por favor, realize o processo de verificação no tempo estipulado.
  </p>
    <div class="form-group" style="margin-top: 50px">
      <button
        (click)="modal.dismiss('Cross click')"
        name="recover-submit"
        class="btn btn-clean-purple"
        value="Trocar senha2"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>

<footer class="p-relative w-100 b-0 l-0" style="z-index: 10; bottom: 0">
  <img
    style="
      margin: 0 auto;
      align-items: center;
      display: flex;
      height: auto;
      width: 100%;
    "
    src="https://paineis-esus-bucket-new.s3.sa-east-1.amazonaws.com/footer-resize2.jpg"
    alt="some text"
    class="center"
  />
</footer>
